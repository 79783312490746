
import React, { Component } from 'react'
import { Card, Button } from '@material-ui/core'

class WidgetSupport extends Component {

  render() {

    return (
      <Card className="widget support" style={{ animationDelay: this.props.animationDelay }}>
        <div className="widgetLabel">Here For You</div>

        <p>Got questions? Let us know how we can help!</p>

        <div className="flexRow">
          <div>
            <div className="icon support" />
          </div>
          <div className="links">
            <a target={"_blank"} href="https://mobilemind.zendesk.com/hc/en-us">
              <Button className="button small">
                <span className="icon book"></span>Knowledge Base
              </Button>
            </a>
            <a target={"_blank"} href="https://mobilemind.zendesk.com/hc/en-us/requests/new">
              <Button className="button small">
                <span className="icon ticket"></span>Submit a Ticket
              </Button>
            </a>
          </div>
        </div>
      </Card>
    )
  }
}

export default WidgetSupport