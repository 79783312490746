import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import session from 'common/reducers/session'
import moment from 'moment'

const navbar = (state = {}, action) => {

  switch (action.type) {
    case 'CHANGE_NAVBAR':
      return { ...state, activeItem: action.activeItem }
    default:
      return state;
  }
}

const extension = (state = {}, action) => {
  switch (action.type) {
    case 'GET_EXTENSION_VERSION':
      return { ...state, version: action.version }
    default:
      return state;
  }
}

const courses = (state = { courseData: [] }, action) => {
  switch (action.type) {
    case 'GET_COURSES':

      let courseData = state.courseData.map(course => course)

      action.data.forEach((course) => {
        // Compare the id of this course to the existing course array
        let found = courseData.some((existing) => {
          return existing.id === course.id
        })

        if (!found) {
          courseData.push(course)
        }
      })

      return { ...state, fetched: action.fetched, courseData }
    default:
      return state;
  }
}

const learningPaths = (state = { paths: [], fetched: false }, action) => {
  switch (action.type) {
    case 'GET_LEARNING_PATHS':

      // Match learning paths with their images
      let paths = action.data
      paths.forEach((path) => {
        if (path.relationships.field_image.data) {
          path.image = action.included.find(image => image.id === path.relationships.field_image.data.id)
        }
      })

      let types = []
      paths.forEach((path) => {
        if (path.relationships.field_type.data) {
          let type = action.included.find(type => type.id === path.relationships.field_type.data.id)
          path.type = type.attributes.name
          types.push(type)
        }
      })

      // Don't include learning paths unless they have all of this information
      let filteredPaths = paths.filter((path) => {
        let description = path.attributes.field_description
        let image = path.image
        let courses = path.relationships.field_courses.data.length
        let type = path.type
        return description && image && courses && type
      })

      return { types: Array.from(new Set(types)), paths: filteredPaths, fetched: action.fetched }
    default:
      return state
  }
}

const badges = (state = { badgeData: [], fetched: false }, action) => {
  switch (action.type) {
    case 'GET_BADGES':

      let badgeImages = action.badgeImages

      let badges = action.badges.map((badge) => {
        if (badge.relationships.field_badge_image.data) {
          badge.image = badgeImages.find(image => image.id === badge.relationships.field_badge_image.data.id)
        }
        return badge
      })

      return { badgeData: badges, fetched: action.fetched }
    default:
      return state;
  }
}

const sidebar = (state = {}, action) => {
  switch (action.type) {
    case 'COLLAPSE':
      return { ...state, isCollapsed: true }
    case 'SHOW':
      return { ...state, isCollapsed: false }
    case 'SELECT_ITEM':
      let sidebar = { ...state }
      sidebar.pages[action.page].activeItem = action.activeItem
      return sidebar
    case 'EXPAND_MOBILE_FILTERS':
      return { ...state, mobileFiltersExpanded: action.data }
    case 'UPDATE_EXPLORE_FILTERS':
      sidebar = { ...state }
      sidebar.pages.learn.items.find(item => item.id === 'explore').filters = action.data
      return sidebar
    case 'UPDATE_LEARNING_PATH_FILTERS':
      sidebar = { ...state }
      sidebar.pages.learn.items.find(item => item.id === 'learningPaths').filters = action.data
      return sidebar
    case 'UPDATE_BADGE_FILTERS':
      sidebar = { ...state }
      sidebar.pages.learn.items.find(item => item.id === 'badges').filters = action.data
      return sidebar
    case 'UPDATE_BADGES_EARNED_FILTERS':
      sidebar = { ...state }
      sidebar.pages.achievements.items.find(item => item.id === 'badgesEarned').filters = action.data
      return sidebar
    case 'UPDATE_COMPLETED_FILTERS':
      sidebar = { ...state }
      sidebar.pages.achievements.items.find(item => item.id === 'completed').filters = action.data
      return sidebar
    default:
      return state;
  }
}

const assessments = (state = { assessmentData: [], fetched: false }, action) => {
  switch (action.type) {
    case 'FETCHING_ASSESSMENTS':
      return { ...state, fetched: false }
    case 'GET_ASSESSMENTS':

      // Clone our existing assessments
      let assessmentData = [...state.assessmentData]

      action.data.forEach((assessment) => {
        // Compare the id of this assessment to the existing assessment array
        let found = assessmentData.some((existing) => {
          return existing.id === assessment.id
        })

        if (!found) {
          assessmentData.push(assessment)
        }
      })
      return { ...state, assessmentData: assessmentData, fetched: action.fetched }

    default:
      return state;
  }
}

const jobTitles = (state = [], action) => {
  switch (action.type) {
    case 'GET_JOB_TITLES':
      return action.data.data
    default:
      return state;
  }
}
const categories = (state = [], action) => {
  switch (action.type) {
    case 'GET_CATEGORIES':
      return action.data.data
    default:
      return state;
  }
}

const announcements = (state = { data: [], media: [], surveys: [] }, action) => {
  switch (action.type) {
    case 'GET_ANNOUNCEMENTS':

      // Filter out expired announcements
      let announcements = action.data.filter(announcement => moment().isBefore(announcement.attributes.field_expiration_date))

      let images = action.included && action.included.filter(included => included.type === "file--image")
      let videos = action.included && action.included.filter(included => included.type === "file--video")
      let surveys = action.included && action.included.filter(included => included.type === "survey_entity_type--survey_entity_type")
      let types = action.included && action.included.filter(included => included.type === "taxonomy_term--announcement_type")

      return { data: announcements, images: images, videos: videos, surveys: surveys, types: types }
    default:
      return state;
  }
}

const viewStyle = (state = 'grid', action) => {
  switch (action.type) {
    case 'SET_VIEW_STYLE':
      return action.data
    default:
      return state
  }
}

const productTour = (state = {}, action) => {
  switch (action.type) {
    case 'START_PRODUCT_TOUR':
      return { isStarted: true, trigger: action.trigger }
    case 'END_PRODUCT_TOUR':
      return { isStarted: false, trigger: action.trigger, hasStarted: true }
    default:
      return state
  }
}

const onboarding = (state = { started: false }, action) => {
  switch (action.type) {
    case 'START_ONBOARDING':
      state = { ...state, started: true }
      return state
    case 'END_ONBOARDING':
      state = { ...state, started: false }
      return state
    default:
      return state
  }
}

const notifications = (state = [], action) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS':
      state = action.data
      return state
    default:
      return state
  }
}

const reducers = combineReducers({
  session,
  extension,
  courses,
  learningPaths,
  badges,
  jobTitles,
  categories,
  assessments,
  navbar,
  sidebar,
  announcements,
  viewStyle,
  productTour,
  onboarding,
  notifications,
  routing: routerReducer,
})

export default reducers
