import React, { Component } from 'react'
import { connect } from 'react-redux'
import Tour from 'reactour'

import { Dialog, Button } from '@material-ui/core'
import { setSidebar, endProductTour } from '../actions'

const mapStateToProps = ({ productTour }) => {
  return {
    productTour,
  }
}

const mapDispatchToProps = {
  setSidebar,
  endProductTour
}

class ProductTour extends Component {

  state = {
    isTourOpen: false,
    isDialogOpen: false
  }

  componentDidUpdate = (prevProps) => {
    const { productTour } = this.props
    if (prevProps.productTour.isStarted !== productTour.isStarted) {
      if (productTour.isStarted) {
        if (!productTour.trigger) {
          this.setState({ isDialogOpen: true })
        }
        else {
          this.setState({ isTourOpen: true })
          this.props.setSidebar('learn', 'dashboard')
        }
      }
    }
  }

  onStepChange = (currentStep) => {
  
    if (currentStep === 0) {
      this.props.setSidebar('learn', 'dashboard')
    }
    if (currentStep === 1) {
      this.props.setSidebar('learn', 'learningPaths')
    }
    if (currentStep === 2) {
      this.props.setSidebar('learn', 'learningPaths')
    }
    if (currentStep === 3) {
      this.props.setSidebar('learn', 'badges')
    }
    if (currentStep === 4) {
      this.props.setSidebar('learn', 'explore')
    }
    if (currentStep === 8) {
      this.props.setSidebar('learn', 'dashboard')
    }
  }

  startTour = () => {
    this.setState({
      isTourOpen: true,
      isDialogOpen: false
    })
  }

  closeTour = () => {
    this.setState({ isTourOpen: false, isDialogOpen: false })
    this.props.endProductTour()
  }

  render() {
    const { isTourOpen, isDialogOpen } = this.state

    const tourSteps = [
      {
        selector: '#tour-sidebar-dashboard button',
        content: ({goTo}) => (
          <div>
            The <strong>Dashboard</strong> has course updates, community events, and news from the MobileMind staff.
            <footer className="flexEnd">
            <Button className="button disabled small">Next</Button>
              <div className="step">1 / 9</div>
              <Button onClick={() => goTo(1)} className="button small">Next</Button>
            </footer>
          </div>
        ),
        style: { marginLeft: 10 }
      },
      {
        selector: '#tour-sidebar-learning-paths button',
        content: ({goTo}) => (
          <div>
            <strong>Learning Paths</strong> are collections of themed courses designed to get you on your way to rapidly mastering skills and earning badges.
            <footer>
              <Button onClick={() => goTo(0)} className="button small">Back</Button>
              <div className="step">2 / 9</div>
              <Button onClick={() => goTo(2)} className="button small">Next</Button>
            </footer>
          </div>
        ),
        style: { marginLeft: 10 }
      },
      {
        selector: '#tour-sidebar-filters',
        content: ({goTo}) => (
          <div>
            Use <strong>Filters</strong> in the sidebar to control the content you see.
            <footer>
              <Button onClick={() => goTo(1)} className="button small">Back</Button>
              <div className="step">3 / 9</div>
              <Button onClick={() => goTo(3)} className="button small">Next</Button>
            </footer>
          </div>
        ),
        style: { marginLeft: 10 }
      },
      {
        selector: '#tour-sidebar-earn-badges button',
        content: ({goTo}) => (
          <div>
            <strong>Earn Badges</strong> by successfully completing micro-course challenges. Use this tab to track badges you haven't earned yet.
            <footer>
              <Button onClick={() => goTo(2)} className="button small">Back</Button>
              <div className="step">4 / 9</div>
              <Button onClick={() => goTo(4)} className="button small">Next</Button>
            </footer>
          </div>
        ),
        style: { marginLeft: 10 }
      },
      {
        selector: '#tour-sidebar-explore button',
        content: ({goTo}) => (
          <div>
            The <strong>Explore</strong> tab lets you dive into the full MobileMind library. Search for courses, or filter by Category or Learning Path.
            <footer>
              <Button onClick={() => goTo(3)} className="button small">Back</Button>
              <div className="step">5 / 9</div>
              <Button onClick={() => goTo(5)} className="button small">Next</Button>
            </footer>
          </div>
        ),
        style: { marginLeft: 10 }
      },
      {
        selector: '#tour-navbar-in-progress',
        content: ({goTo}) => (
          <div>
            Use the <strong>In Progress</strong> page to track the courses you’ve saved, started, or submitted, and view feedback from MobileMind reviewers.
            <footer>
              <Button onClick={() => goTo(4)} className="button small">Back</Button>
              <div className="step">6 / 9</div>
              <Button onClick={() => goTo(6)} className="button small">Next</Button>
            </footer>
          </div>
        ),
        style: { margin: 10 }
      },
      {
        selector: '#tour-navbar-achievements',
        content: ({goTo}) => (
          <div>
          Your <strong>Achievements</strong> page includes every course you’ve completed as well as all the badges you’ve earned.
          <footer>
            <Button onClick={() => goTo(5)} className="button small">Back</Button>
            <div className="step">7 / 9</div>
            <Button onClick={() => goTo(7)} className="button small">Next</Button>
          </footer>
          </div>
        ),
        style: { margin: 10 }
      },
      {
        selector: '.navbar .profilePicture',
        content: ({goTo}) => (
          <div>
          Use the <strong>Profile Menu</strong> to update your personal information and picture, as well as access other helpful links.
          <footer>
            <Button onClick={() => goTo(6)} className="button small">Back</Button>
            <div className="step">8 / 9</div>
            <Button onClick={() => goTo(8)} className="button small">Next</Button>
          </footer>
          </div>
        ),
        style: { marginLeft: -10 }
      },
      {
        selector: '#tour-mm-logo',
        content: ({goTo}) => (
          <div>
          You can click the <strong>MobileMind</strong> logo at any time to go back to your dashboard.
          <footer>
            <Button onClick={() => goTo(7)} className="button small">Back</Button>
            <div className="step">9 / 9</div>
            <Button onClick={this.closeTour} className="button small">Finish</Button>
          </footer>
          </div>
        ),
        style: { margin: 10 }
      }
    ]
    
    return (
      <>
        <Dialog open={isDialogOpen}>
          <div className="dialog">
            <h2>Let's show you around.</h2>
            <div className="flexRow">
              <div className="thumbnail">
                <div className="icon map"/>
              </div>
              <p>To help you get the most out of MobileMind, we’ve put together a short tour of the app. If you want to skip the tour, you can start it later at any time from the Profile menu.</p>
            </div>
            <footer>
              <Button onClick={this.closeTour} className="button small">Skip for now</Button>
              <Button onClick={this.startTour} className="button large primary">Start Tour</Button>
            </footer>
          </div>
        </Dialog>
        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          startAt={0}
          showNavigationNumber={true}
          showNavigation={false}
          showNumber={false}
          maskClassName="tour-mask"
          getCurrentStep={currentStep => this.onStepChange(currentStep)}
          showButtons={false}
          prevButton={<Button className="button small">Back</Button>}
          nextButton={<Button className="button small">Next</Button>}
          maskSpace={0}
          onRequestClose={this.closeTour}
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTour);