import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dialog, Card, Button, TextField, FormControl } from '@material-ui/core'
import { Link } from 'react-router'
import classNames from 'classnames'

import { getJobTitles } from '../actions'
import { getCategoryLabel, isCourseCategoryExcluded } from '../functions'

import { createUserLearningPath, endOnboarding } from '../actions'
import { getUserLearningPaths, getLearningPaths, getCourses } from 'common/actions/course'
import { updateProfile, getUser } from 'common/actions/user'

import CourseListItem from './CourseListItem'
import SanitizedHTML from './SanitizedHTML'

const mapStateToProps = ({ jobTitles, assessments, onboarding, courses, learningPaths, categories, session }) => {

  let filteredCategories = categories.filter((category) => {
    // Filter out excluded categories if we have a group
    return session.excludedCategories ? !session.excludedCategories.find(cat => cat.target_id === category.attributes.drupal_internal__tid) : category
  })

  let availableCategories = filteredCategories.filter((category) => {
    let learningPathCategories = [
      'Calendar',
      'Classroom',
      'Docs',
      'Forms',
      'Drive',
      'Slides',
      'Sheets'
    ]
    return learningPathCategories.includes(category.attributes.name)
  })

  // @todo - this isn't a real category
  availableCategories.push({ id: 'meet', attributes: { name: 'Meet' } })

  let parentSchool = jobTitles.find(title => title.attributes.name === 'School')
  let parentDistrict = jobTitles.find(title => title.attributes.name === 'District')
  let schoolRoles = jobTitles.filter(title => title.relationships.parent.data[0].id === parentSchool.id)
  let districtRoles = jobTitles.filter(title => title.relationships.parent.data[0].id === parentDistrict.id)

  let goals = learningPaths.types
  let paths = learningPaths && { ...learningPaths }.paths

  if (courses.courseData.length) {
    paths.forEach((path) => {

      let pathCourses = courses.courseData && path.relationships.field_courses.data.map((pathCourse) => {
        return courses.courseData.find(course => pathCourse.id === course.id)
      })

      pathCourses.forEach((course) => {
        if (course) {
          course.category = categories.find(category => category.id === course.relationships.field_category.data.id)
        }
      })

      path.courses = pathCourses
      path.categories = pathCourses && Array.from(new Set(pathCourses.map((course) => {
        return course && categories.find(category => category.id === course.relationships.field_category.data.id)
      })
      ))
    })
  }

  // Filter out archived learning paths
  let nonArchivedPaths = learningPaths.paths.filter(path => !path.attributes.field_learning_path_archive)

  return {
    onboarding,
    schoolRoles,
    districtRoles,
    jobTitles,
    goals,
    courses,
    assessments,
    learningPaths: nonArchivedPaths,
    categories,
    availableCategories,
    session,
  }
}

const mapDispatchToProps = {
  endOnboarding,
  getJobTitles,
  createUserLearningPath,
  getLearningPaths,
  getUserLearningPaths,
  getUser,
  getCourses,
  updateProfile
}

class Onboarding extends Component {

  state = {
    open: true,
    pageIndex: 0,
    jobType: '',
    jobRole: '',
    jobRoleOther: '',
    goal: '',
    goalSpecific: '',
    skillLevel: ''
  }

  componentDidMount = () => {
    this.props.getJobTitles()
    this.props.getCourses()
    this.props.getLearningPaths()
  }

  selectJobType = (jobType) => {
    this.setState({ jobType: jobType, jobRole: '', goal: '', goalSpecific: '', jobRoleOther: '', skillLevel: '' })
    setTimeout(() => {
      this.setState({ pageIndex: 1 })
    }, 500)
  }

  selectJobRole = (role) => {
    const { session } = this.props

    let roleLabel = this.props.jobTitles.find(title => title.id === role).attributes.name
    this.setState({ jobRole: role, goal: '', goalSpecific: '', jobRoleOther: '', skillLevel: '' })

    setTimeout(() => {
      if (!roleLabel.includes('Other')) {
        this.setState({ pageIndex: 2 })
      }
    }, 500)

    this.props.updateProfile({
      name: session.user.attributes.field_name,
      jobTitle: role
    }, session)

  }

  setJobRoleOther = (event) => {
    this.setState({ jobRoleOther: event.target.value })
  }

  submitJobRoleOther = () => {
    const { session } = this.props
    this.setState({ pageIndex: 2 })

    this.props.updateProfile({
      name: session.user.attributes.field_name,
      jobTitle: this.state.jobRole,
      jobTitleOther: this.state.jobRoleOther
    }, session)
  }

  selectGoal = (goal) => {
    this.setState({ goal: goal, goalSpecific: '', skillLevel: '' })

    setTimeout(() => {
      let goalLabel = goal && this.props.goals.find(goal => goal.id === this.state.goal).attributes.name
      if (goalLabel !== 'Master a Google App' && goalLabel !== 'Earn a Certification') {
        this.setState({ pageIndex: 4 })
      }
      else {
        this.setState({ pageIndex: 3 })
      }
    }, 500)
  }

  setGoalSpecific = (goal) => {
    this.setState({ goalSpecific: goal, skillLevel: '' })
    setTimeout(() => {
      this.setState({ pageIndex: 4 })
    }, 500)
  }

  goBack = () => {
    if (this.state.pageIndex === 4 && !this.state.goalSpecific) {
      this.setState({ pageIndex: this.state.pageIndex - 2 })
    }
    else {
      this.setState({ pageIndex: this.state.pageIndex - 1 })
    }
  }

  finish = (skillLevel) => {
    const { learningPaths, goals, assessments, categories, session } = this.props
    this.setState({ skillLevel: skillLevel })

    // Handle learning path recommendation logic
    let activeLearningPath
    let goalLabel = this.state.goal && goals.find(goal => goal.id === this.state.goal).attributes.name
    let pathOne = learningPaths.find(path => path.attributes.name === 'MobileMind Fundamentals')
    let pathTwo = learningPaths.find(path => path.attributes.name === 'Distance Learning Essentials')
    let pathThree = learningPaths.find(path => path.attributes.name === this.state.goalSpecific)

    // Check if they've indicated no competence
    if (skillLevel === 1 && pathOne) {
      activeLearningPath = pathOne
    }
    // If they have indicated distance learning
    else if (goalLabel === 'Distance Learning' && pathTwo) {
      activeLearningPath = pathTwo
    }
    // If they want to learn a google app
    else if (goalLabel === 'Master a Google App' && pathThree) {
      activeLearningPath = pathThree
    }
    // If they want to earn a certification
    else if (goalLabel === 'Earn a Certification' && pathThree) {
      activeLearningPath = pathThree
    }
    // Otherwise try to find something based on their job role
    else {
      let targetType = goals.find(goal => goal.id === this.state.goal)
      let roleLearningPaths = learningPaths.filter((path) => {
        let hasJobTitle = path.relationships.field_job_title_applicability.data.find(title => title.id === this.state.jobRole)
        return hasJobTitle && path.type === targetType.attributes.name
      })

      activeLearningPath = roleLearningPaths && roleLearningPaths[0]
    }
    // If nothing else, suggest Google Basics
    if (!activeLearningPath) {
      activeLearningPath = learningPaths.find(path => path.attributes.name === 'Google Basics')
    }

    /**
     * Get the first course in the learning path that they have no assessment for,
     * is not archived, and is not excluded based on category
     */
    activeLearningPath.nextCourse = activeLearningPath.courses.find((course) => {
      let hasAssessment = assessments.assessmentData.find(assessment => assessment.relationships.field_course.data.id === course.id)
      let isArchived = course.attributes.field_archive
      let isExcluded = session.excludedCategories && isCourseCategoryExcluded(course, categories, session.excludedCategories)
      return !hasAssessment && !isArchived && !isExcluded
    })

    this.setState({ activeLearningPath: activeLearningPath })

    setTimeout(() => {
      this.setState({ pageIndex: 5 })
    }, 500)
    setTimeout(() => {
      this.setState({ progressBarFade: true })
    }, 1000)
  }

  startLearningPath = async () => {
    const { courses, assessments } = this.props

    this.props.endOnboarding()
    this.setState({ pageIndex: 6 })

    // If this is a returning user check for any completed courses before we create the ULP
    let pathCourses = this.state.activeLearningPath.relationships.field_courses.data.map((pathCourse) => {
      return courses.courseData.find(course => course.id === pathCourse.id)
    }).filter(course => !course.attributes.field_archive)

    let completedCourseIds = pathCourses.filter((course) => {
      let assessment = assessments.assessmentData.find(assessment => assessment.relationships.field_course.data.id === course.id)
      return assessment && assessment.attributes.field_status === 'completed'
    }).map(course => course.id)

    await this.props.createUserLearningPath(this.props.session, this.state.activeLearningPath, completedCourseIds)
    this.props.getUserLearningPaths(this.props.session.user)
  }

  exit = async () => {
    await this.props.getUser(this.props.session.user.attributes.drupal_internal__uid)
    this.props.endOnboarding()
    this.setState({ open: false })
  }

  render() {
    const { pageIndex, jobType, jobRole, jobRoleOther, goal, goalSpecific, activeLearningPath } = this.state
    const { availableCategories, goals, learningPaths } = this.props

    let roles = jobType === 'school' ? this.props.schoolRoles : this.props.districtRoles
    let roleLabel = roles && jobRole && roles.find(role => role.id === jobRole).attributes.name
    let goalLabel = goal && goals.find(goal => goal.id === this.state.goal).attributes.name

    let progress = pageIndex > 1 ? ((pageIndex - 1) / 4 * 100) : 2

    let certificationLearningPaths = learningPaths.filter(path => path.type === 'Earn a Certification')

    return (
      <Dialog open={this.state.open} id="onboarding" data-onboarding-started={this.props.onboarding.started}>
        <div className="dialog" data-page={pageIndex}>
          {
            pageIndex <= 4 &&
            <header className="history">
              {
                pageIndex >= 1 &&
                <div className="response">
                  <div className={classNames('onboarding-icon', jobType)} />
                  <div className="title">{jobType === 'school' ? <>School Employee</> : <>District Employee</>}</div>
                </div>
              }
              {
                pageIndex >= 2 &&
                <div className="response">
                  <div className={classNames('onboarding-icon', roleLabel.toLowerCase())} />
                  <div className="title">
                    {jobRoleOther ? jobRoleOther : roleLabel}
                  </div>
                </div>
              }
              {
                pageIndex >= 3 &&
                <div className="response">
                  <div className={classNames('onboarding-icon', goalLabel.toLowerCase())} />
                  <div className="title" dangerouslySetInnerHTML={{ __html: goalLabel }}></div>
                </div>
              }
              {
                pageIndex >= 4 && goalSpecific &&
                <div className="response">
                  <div className={classNames('onboarding-icon category-icon', goalSpecific.toLowerCase())} />
                  <div className="title" dangerouslySetInnerHTML={{ __html: goalSpecific }}></div>
                </div>
              }
            </header>
          }

          <div className="progressWrapper" style={this.state.progressBarFade && { opacity: 0 }}>
            <div className="progress" style={{ width: progress + '%' }}>
              <div className="inner" />
            </div>
          </div>

          {
            pageIndex === 0 &&
            <>
              <span className="icon logo circle" />
              {
                this.props.assessments.assessmentData.length > 0 ?
                  <>
                    <h2>Welcome back!</h2>
                    <p>To ensure we continue to deliver the best possible experience for you, we'd like to ask you a couple of questions.</p>
                  </>
                  :
                  <>
                    <h2>Welcome to MobileMind!</h2>
                    <p>Before you get started, we'd like to ask you a couple of questions to ensure we deliver the best possible experience for you.</p>
                  </>
              }
              <form>
                <header>Are you a School or District Employee?</header>
                <div className="flexRow selectGrid">
                  <Button
                    style={{ animationDelay: .1 + 's' }}
                    className={classNames("button small grid", jobType === 'school' && 'active')}
                    onClick={() => { this.selectJobType('school') }}>
                    <div className={'onboarding-icon school'} />
                    <div className="title">School Employee</div>
                  </Button>
                  <Button
                    style={{ animationDelay: .1 + 's' }}
                    className={classNames("button small grid", jobType === 'district' && 'active')}
                    onClick={() => { this.selectJobType('district') }}>
                    <div className={'onboarding-icon district'} />
                    <div className="title">District Employee</div>
                  </Button>
                </div>
              </form>
            </>
          }

          {
            pageIndex === 1 &&
            <>

              <header>Which of these best describes your role?</header>
              <div className="flexRow selectGrid roles">
                {
                  roles.map((role, index) => {
                    return (
                      <Button
                        key={role.id}
                        style={{ animationDelay: index * .1 + 's' }}
                        className={classNames("button small grid", jobRole === role.id && 'active')}
                        onClick={() => { this.selectJobRole(role.id) }}>
                        <div className={classNames('onboarding-icon', role.attributes.name.toLowerCase())} />
                        <div className="title" dangerouslySetInnerHTML={{ __html: role.attributes.name }}></div>
                      </Button>
                    )
                  })
                }
                {
                  roleLabel.includes('Other') &&
                  <FormControl>
                    <TextField
                      variant="standard"
                      label="Please specify your job title"
                      value={jobRoleOther}
                      onChange={this.setJobRoleOther}
                    />
                  </FormControl>
                }
              </div>

              <footer className="actions">
                <Button onClick={this.goBack} className="button small">Back</Button>
                {
                  roleLabel.includes('Other') && jobRoleOther &&
                  <Button onClick={this.submitJobRoleOther} className="button small">Continue</Button>
                }
              </footer>
            </>
          }

          {
            pageIndex === 2 &&
            <>
              <form>
                <header>What are you looking to do first with MobileMind?</header>
                <div className="flexRow selectGrid goals">
                  {
                    goals.map((goal, index) => {
                      return (
                        <Button
                          key={index}
                          style={{ animationDelay: index * .1 + 's' }}
                          className={classNames("button small grid", this.state.goal === goal.id && 'active')}
                          onClick={() => { this.selectGoal(goal.id) }}>
                          <div className={classNames('onboarding-icon', goal.attributes.name.toLowerCase())} />
                          <div className="title" dangerouslySetInnerHTML={{ __html: goal.attributes.name }}></div>
                        </Button>
                      )
                    })
                  }
                </div>
              </form>
              <footer className="actions">
                <Button onClick={this.goBack} className="button small">Back</Button>
              </footer>
            </>
          }

          {
            pageIndex === 3 &&
            <>
              <form>
                {
                  goalLabel === 'Master a Google App' &&
                  <>
                    <header>Which Google App are you interested in learning?</header>
                    <div className="flexRow selectGrid">
                      {
                        availableCategories.map((category, index) => {
                          return (
                            <Button
                              key={category.id}
                              style={{ animationDelay: index * .1 + 's' }}
                              className={classNames("button small grid", this.state.goalSpecific === category.attributes.name && 'active')}
                              onClick={() => { this.setGoalSpecific(category.attributes.name) }}>

                              <div className={classNames('onboarding-icon category-icon', category.attributes.name.toLowerCase())} />
                              <div className="title">{category.attributes.name}</div>
                            </Button>
                          )
                        })
                      }
                    </div>
                  </>
                }

                {
                  goalLabel === 'Earn a Certification' &&
                  <>
                    <header>Which certification are you interested in earning?</header>
                    <div className="flexRow selectGrid certification">
                      {
                        certificationLearningPaths.map((path, index) => {
                          return (
                            <Button
                              key={path.id}
                              style={{ animationDelay: index * .1 + 's' }}
                              className={classNames("button small grid", this.state.goalSpecific === path.attributes.name && 'active')}
                              onClick={() => { this.setGoalSpecific(path.attributes.name) }}>
                              <div className={classNames('onboarding-icon category-icon certification')} />
                              <div className="title">{path.attributes.name}</div>
                            </Button>
                          )
                        })
                      }
                    </div>
                  </>
                }
              </form>
              <footer className="actions">
                <Button onClick={this.goBack} className="button small">Back</Button>
              </footer>
            </>
          }

          {
            pageIndex === 4 &&
            <>
              <form>
                <>
                  <header>How would you rate your level of comfort with a computer?</header>
                  <div className="flexRow selectGrid">
                    <Button
                      style={{ animationDelay: 0 + 's' }}
                      className={classNames("button small grid", this.state.skillLevel === 1 && 'active')}
                      onClick={() => { this.finish(1) }}>

                      <div className={classNames('onboarding-icon skill none')} />
                      <div className="title">Low</div>
                    </Button>

                    <Button
                      style={{ animationDelay: .1 + 's' }}
                      className={classNames("button small grid", this.state.skillLevel === 2 && 'active')}
                      onClick={() => { this.finish(2) }}>

                      <div className={classNames('onboarding-icon skill some')} />
                      <div className="title">Medium</div>
                    </Button>

                    <Button
                      style={{ animationDelay: .2 + 's' }}
                      className={classNames("button small grid", this.state.skillLevel === 3 && 'active')}
                      onClick={() => { this.finish(3) }}>

                      <div className={classNames('onboarding-icon skill alot')} />
                      <div className="title">High</div>
                    </Button>
                  </div>
                </>

              </form>
              <footer className="actions">
                <Button onClick={this.goBack} className="button small">Back</Button>
              </footer>
            </>
          }

          {
            pageIndex === 5 &&
            <>
              <h2>Let's Get Started!</h2>
              <p>
                <span className="icon learningPath" /><strong>Learning Paths</strong> are collections of themed courses.
              </p>
              <p>Based on your answers, we recommend starting with the <strong>{this.state.activeLearningPath.attributes.name}</strong> learning path.</p>
              <div className="learningPathWrapper">
                <Card
                  className={classNames("widget learningPath")}>
                  <Link onClick={this.startLearningPath} to={'learning-path/' + activeLearningPath.attributes.drupal_internal__id}>
                    <div className="topContent">
                      <div className={'widgetLabel'}>{activeLearningPath.type}
                      </div>
                      <div className="pathImage" style={{ backgroundImage: 'url("' + process.env.REACT_APP_API_URL + activeLearningPath.image.attributes.uri.url + '")' }} />
                    </div>
                    <div className="bottomContent">
                      <h2>{activeLearningPath.attributes.name}</h2>
                      <SanitizedHTML html={activeLearningPath.attributes.field_description} />
                      {
                        activeLearningPath.categories &&
                        <ul className="categoryList">
                          {
                            activeLearningPath.categories.map((category) => {
                              return (
                                <li key={category.id}>
                                  <div className='category-icon-wrapper'>
                                    <span className={classNames('category-icon', getCategoryLabel(activeLearningPath.categories, category.id))}></span>
                                    {getCategoryLabel(activeLearningPath.categories, category.id)}
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      }

                    </div>
                  </Link>
                </Card>
                <footer className="actions" style={{ justifyContent: 'center' }}>
                  <Link to="/">
                    <Button onClick={this.exit} className="button small black">No Thanks, I'll Explore on My Own</Button>
                  </Link>
                </footer>
              </div>
            </>
          }

          {
            pageIndex === 6 &&
            <>
              <h2>Almost there!</h2>
              <p>The first course to start this learning path is <strong>{activeLearningPath.nextCourse.attributes.name}</strong>. Select this course to review your objective and get started!</p>
              <div onClick={this.exit}>
                <CourseListItem
                  course={activeLearningPath.nextCourse}
                  category={activeLearningPath.nextCourse.category}
                />
              </div>
              <footer className="actions" style={{ justifyContent: 'center' }}>
                <Link to="/">
                  <Button onClick={this.exit} className="button small black">No Thanks, I'll Explore on My Own</Button>
                </Link>
              </footer>
            </>
          }

        </div>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);