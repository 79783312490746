import React, { Component } from 'react'
import { connect } from 'react-redux'
import Navbar from './Navbar.js'
import { isChrome, isMobile } from 'react-device-detect'

import Onboarding from './Onboarding'
import ProductTour from './ProductTour'
import { getAssessments } from 'common/actions/course'
import { startOnboarding } from '../actions'

import '../styles/index.scss'
import '../styles/cards.scss'

const mapStateToProps = ({ assessments, onboarding, session, sidebar, extension, productTour }) => {

  return {
    assessments,
    onboarding,
    session,
    extension,
    productTour,
    isCollapsed: sidebar.isCollapsed
  }
}

const mapDispatchToProps = {
  getAssessments,
  startOnboarding
}

class App extends Component {
  state = {
    hasJobTitle: false
  }

  componentDidMount = () => {
    /**
     * We need to get assessments here instead of in LearnLayout to determine
     * whether we should show the onboarding flow or not
     */
    this.props.getAssessments()

    /**
     * If they've got no job title, start onboarding, store it 
     * in the state if they do in case they change it later
     */
    if (!this.state.hasJobTitle && !this.props.session.user.jobTitle) {
      this.props.startOnboarding()
    }
    else {
      this.setState({ hasJobTitle: this.props.session.user.jobTitle })
    }

  }

  render() {
    const { hasJobTitle } = this.state
    const { extension, assessments } = this.props
    let browserWarning
    let storeHref = 'https://chrome.google.com/webstore/detail/mobilemind-extension/' + process.env.REACT_APP_EXTENSION_ID + '?hl=en'
    if (!isMobile) {
      if (!isChrome) {
        browserWarning = <>It looks like you aren't using <a href="https://www.google.com/chrome/">Google Chrome</a>, so you won't be able to start or resume any micro-course challenges.</>
      }
      else if (isChrome && !extension.version) {
        browserWarning = <>It looks like you don't have the <a href={storeHref}>MobileMind Extension</a> installed. Add the extension to Chrome and refresh this page to start or resume challenges.</>
      }
      else if (isChrome && extension.version !== process.env.REACT_APP_VERSION) {
        browserWarning = <>It looks like you don't have the latest version of the MobileMind Extension installed. Go to <strong>Window > Extensions</strong>, enable <strong>Developer Mode</strong> in the top right corner, then click <strong>Update</strong> in the toolbar that appears.</>
      }  
    }

    let showOnboarding = assessments.fetched && !hasJobTitle
    let showMain = hasJobTitle || !this.props.onboarding.started

    return (
      <div className="appWrapper">
        { !isMobile && <ProductTour isOpen={true} /> }
        { showOnboarding && <Onboarding/> }

        {
          showMain &&
          <>
            <Navbar router={this.props.router} />
            <main data-collapsed={this.props.isCollapsed || this.props.location.pathname === "/profile"} data-is-tour-started={this.props.productTour.isStarted}>
              <div className="appInner">
                { browserWarning && <div className="browserWarning">{browserWarning}</div> }
                { this.props.children }
              </div>
            </main>
          </>
        }

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);