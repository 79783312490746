
import React, { Component } from 'react'
import { Link } from 'react-router'
import { Card, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { generateTimestamp, getCategoryLabel } from '../functions'

const mapStateToProps = ({ courses, categories, announcements }, ownProps) => {

  return {
    courses: courses.courseData,
    categories,
    announcementTypes: announcements.types,
  }
}

class WidgetAnnouncement extends Component {

  render() {
    const { announcement, image, video } = this.props.widget
    const { categories, courses } = this.props

    let courseId, course, categoryLabel, coursePath

    let type = this.props.announcementTypes.find(type => type.id === announcement.relationships.field_announcement_type.data.id)
    let label = type.attributes.name
    let isTip = label === 'Tip of the day'
    let isFeaturedCourse = label === 'Featured Course'
    let imageSrc = image && process.env.REACT_APP_API_URL + image.attributes.uri.url
    let videoSrc = video && process.env.REACT_APP_API_URL + video.attributes.uri.url

    if (isFeaturedCourse) {
      courseId = isFeaturedCourse && announcement.relationships.field_course.data.id
      course = courses.find(course => course.id === courseId)
      categoryLabel = getCategoryLabel(categories, course.relationships.field_category.data.id)
      coursePath = "/course/" + course.attributes.drupal_internal__id
    }

    return (
      <Card className={classNames('widget announcement', isFeaturedCourse && 'featuredCourse')} style={{ animationDelay: this.props.animationDelay }}>
        <div className={classNames('widgetLabel', label.toLowerCase())}>{label}</div>        
        {
          !isTip &&
          <div className='timestamp'>Posted {generateTimestamp(announcement.attributes.created)}</div>
        } 
        {
          !isTip && !isFeaturedCourse &&
          <h2>{announcement.attributes.title}</h2>
        }
        {
          imageSrc && !isTip &&
          <div className="announcementImage">
            <img src={imageSrc} alt={image.attributes.field_image_alt_text} />
          </div>
        }
        {
          video &&
          <div className="videoWrapper">
            <video controls>
              <source src={videoSrc} type="video/mp4"/>
            </video>
          </div>
        }
        {
          isTip &&
          <div className="flexRow">
            <div>
              <div className="icon tip" />
            </div>
            <div dangerouslySetInnerHTML={{ __html: announcement.attributes.body && announcement.attributes.body.value}}></div>
          </div>          
        }
        {
          !isTip && !isFeaturedCourse &&
          <div dangerouslySetInnerHTML={{ __html: announcement.attributes.body && announcement.attributes.body.value}}></div>
        }
        {
          isFeaturedCourse &&
          <>
            <div className="card-image" data-bg={categoryLabel}>
              <header>
                <div className="category-icon-wrapper">
                  <span className={classNames('category-icon', categoryLabel)}/>
                </div>
                <div className="course-label">
                  <span className="category-label">{categoryLabel}</span>
                  <div className="course-level">

                  <span className="icon star"></span>
                    { 
                      course.attributes.field_level.toLowerCase() === 'intermediate' &&
                      <span className="icon star"></span>
                    }
                    {
                      course.attributes.field_level.toLowerCase() === 'advanced' && 
                      <>
                        <span className="icon star"></span>
                        <span className="icon star"></span>
                      </> 
                    }
                  </div>
                </div>
              </header>
              <h2>{course.attributes.name}</h2>
            </div>
            <p>{course.attributes.field_course_objective}</p>
            <footer>
              <Link to={coursePath}>
                <Button className="button small">
                  View Course
                </Button>
              </Link>
            </footer>
          </>
        }
      </Card>
    )
  }
}

export default connect(mapStateToProps)(WidgetAnnouncement)