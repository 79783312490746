import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../styles/cards.scss'

import Sidebar from './Sidebar'
import ExploreLayout from './ExploreLayout'
import BadgesLayout from './BadgesLayout'
import LearningPathsLayout from './LearningPathsLayout'
import DashboardLayout from './DashboardLayout'
import Loading from './Loading'

import { getBadges, getCourses } from 'common/actions/course'
import { changeNavbar, getUserBadges } from '../actions'
import { getLearningPaths, getUserLearningPaths } from 'common/actions/course'

const mapStateToProps = ({ session, courses, learningPaths, badges, assessments, sidebar }, ownProps) => {

  return {
    session: session,
    courses: courses,
    learningPaths: learningPaths,
    badges: badges,
    assessments: assessments,
    sidebar: sidebar
  }
}

const mapDispatchToProps = {
  getCourses,
  getLearningPaths,
  getBadges,
  getUserBadges,
  getUserLearningPaths,
  changeNavbar
}

class LearnLayout extends Component {

  state = {
    fetched: false,
    loadingMessage: 'Building Your Dashboard...'
  }

  componentDidMount = async () => {
    const { session, courses, learningPaths, badges } = this.props
    this.props.changeNavbar('learn')

    Promise.all([
      !courses.fetched && this.props.getCourses(this.props.session.trial),
      !learningPaths.fetched && this.props.getLearningPaths(),
      !badges.fetched && this.props.getBadges(),
      !session.fetched.userBadges && this.props.getUserBadges(this.props.session.user),
      !session.fetched.userLearningPaths && this.props.getUserLearningPaths(this.props.session.user)
    ]).then(() => {
      this.setState({ fetched: true })
    })

  }

  render() {
    const page = this.props.sidebar.pages.learn

    return (
      <div className="layout learn">
        <Sidebar currentPage="learn" activeItem={page.activeItem} items={page.items} />
        {
          this.state.fetched ?
            <>
              {page.activeItem === 'dashboard' && <DashboardLayout />}
              {page.activeItem === 'learningPaths' && <LearningPathsLayout />}
              {page.activeItem === 'badges' && < BadgesLayout />}
              {page.activeItem === 'explore' && <ExploreLayout />}
            </>
            :
            <Loading message={this.state.loadingMessage} />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnLayout);
