import moment from 'moment'
import _ from 'lodash'

export function generateTimestamp(date, format) {
  let days = moment().diff(moment(date), 'days')
  let minutes = moment().diff(moment(date), 'minutes')

  if (minutes < 4) {
    return 'Just Now'
  }
  else if (minutes < 30) {
    return minutes + ' minutes ago'
  }
  if (days < 1) {
    return 'Today'
  }
  else if (days === 1) {
    return 'Yesterday'
  }
  else if (days < 7) {
    return days + ' days ago'
  }
  else if (format === 'dateOnly') {
    return moment(date).format('MM/DD/YYYY')
  }
  else {
    return 'on ' + moment(date).format('MM/DD/YYYY')
  }
}


/**
 * Get the user-facing name for a category when all we have is the category id
 * @param {array} allCategories All category entities
 * @param {string} id The id of the category for which to get the full name
 */

export function getCategoryLabel(allCategories, id) {
  return allCategories.find(cat => cat.id === id).attributes.name.toLowerCase()
}


/**
 * Determine which user_learning_path is "active" based on a specific course
 * @param {array} userLearningPaths All of the current user's user_learning_path entities
 * @param {array} allLearningPaths All learning_path entities
 * @param {object} activeCourse The course we want to use to determine which user_learning_path is being used
 */

export function getActiveUserLearningPath(userLearningPaths, allLearningPaths, activeCourse) {
  // Get the most recently updated User Learning Paths that's still in progress
  let inProgressUserLearningPaths = _.orderBy(userLearningPaths.filter(
    path => path.attributes.field_status === "in_progress"
  ), (path) => {
    return moment(path.attributes.changed).format('YYYY-MM-DD HH:MM:SS')
  }, ['desc'])

  // Limit active user paths to those that contain this course, and get the most recent one
  return inProgressUserLearningPaths && inProgressUserLearningPaths.filter((userPath) => {
    let learningPath = allLearningPaths.paths.find(path => path.id === userPath.relationships.field_learning_path.data.id)
    let hasCourse = learningPath && learningPath.relationships.field_courses.data.find(course => course.id === activeCourse.id)
    return hasCourse
  })[0]
}


/**
 * Determine whether a course belongs to a category that the user's organization has chosen to exclude
 * @param {object} course The course to determine whether to exclude
 * @param {array} allCategories All category entities
 * @param {array} excludedCategories Categories the organization has chosen to exclude
 */

export function isCourseCategoryExcluded(course, allCategories, excludedCategories) {
  let excludedCategoryIds = excludedCategories.map((excludedCategory) => {
    return allCategories.find(category => category.attributes.drupal_internal__tid === excludedCategory.target_id).id
  })

  return excludedCategoryIds.includes(course.relationships.field_category.data.id)
}