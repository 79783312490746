
import React, { Component } from 'react'
import { Card, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { setSidebar, updateFilters } from '../actions'
import { Link } from 'react-router'

const mapStateToProps = ({ learningPaths, courses }, ownProps) => {

  return {
    courses: courses.courseData,
    learningPath: learningPaths.paths && learningPaths.paths.find(path => path.id === ownProps.widget.userLearningPath.relationships.field_learning_path.data.id)
  }
}

const mapDispatchToProps = {
  setSidebar,
  updateFilters
}

class WidgetLearningPath extends Component {

  viewLearningPaths = () => {
    this.props.setSidebar('learn', 'learningPaths')
  }

  generateMessage = (percentCompleted, coursesRemaining) => {

    let { learningPath, courses } = this.props

    let completedCourseIds = this.props.widget.userLearningPath.relationships.field_courses_completed.data.map(course => course.id)
    let remainingCourseIds = learningPath.relationships.field_courses.data.filter(course => !completedCourseIds.includes(course.id)).map(course => course.id)
    let nextCourse = courses.find(course => course.id === remainingCourseIds[0])
    let nextCourseTitle = nextCourse && nextCourse.attributes.name

    let message

    if (percentCompleted > 80) {
      message = <>You're so close! Check out the next course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }
    else if (coursesRemaining === 1) {
      message = <>Only one more course to go! Finish this Learning Path by completing <strong>{nextCourseTitle}</strong>.</>
    }
    else if (coursesRemaining > 1 && (percentCompleted >= 50 && percentCompleted < 70)) {
      message = <>Over halfway there! Check out the next course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }
    else if (percentCompleted > 0) {
      message = <>You're off to a great start! Check out the next course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }
    else if (percentCompleted === 0) {
      message = <>Ready for action! Get started with the first course on this Learning Path, <strong>{nextCourseTitle}</strong>.</>
    }

    return message
  }

  render() {
    const { learningPath, courses } = this.props
    const { label, status, userLearningPath } = this.props.widget

    let isCompleted = status === 'completed'
    let pathCourses = learningPath && learningPath.relationships.field_courses.data.map((pathCourse) => {
      return courses.find(course => course.id === pathCourse.id)
    }).filter(course => course && !course.attributes.field_archive)

    let courseTotal = pathCourses.length
    let courseCount = isCompleted ? courseTotal : userLearningPath.relationships.field_courses_completed.data.length
    let percentCompleted = (courseCount / courseTotal) * 100
    let coursesRemaining = courseTotal - courseCount
  
    return (
      <Card
        className="widget learningPath"
        style={{ cursor: !isCompleted && 'pointer', animationDelay: this.props.animationDelay }}>
        <Link to={'learning-path/' + learningPath.attributes.drupal_internal__id}>
          <div className="topContent">
            <div className={'widgetLabel success'}>{label}</div>
            <div className="pathImage" style={{ opacity: .2, backgroundImage: 'url("' + process.env.REACT_APP_API_URL + this.props.learningPath.image.attributes.uri.url + '")' }}/>
        
            <h2>{learningPath.attributes.name}</h2>
            <div className="pathStatus">
              {courseCount} / {courseTotal} Courses Completed
              <div className="statusBarWrapper">
                <div className="statusBar" style={{ width: percentCompleted + '%' }}>
                  <div className="statusBarInner"></div>
                </div>
              </div>
            </div>

          </div>

          {
            isCompleted ?
            <p>You finished all the required courses for this Learning Path! You've mastered new skills and are ready for your next challenge.</p>
            :
            <p>{this.generateMessage(percentCompleted, coursesRemaining)}</p>
          }

          {
            isCompleted ?
            <footer style={{ justifyContent: 'flex-end' }}>
              {/* <div className="certificateInfo">
                <Button className="button small">
                  <span className="icon certificate"></span>
                  View Certificate
                </Button>
              </div> */}
            </footer>
            :
            <footer>
              <Button onClick={this.resumeLearningPath} className="button small">Resume Learning Path</Button>
            </footer>
          }
        </Link>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetLearningPath)