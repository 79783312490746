import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Grid, Button } from '@material-ui/core'

import { changeNavbar } from '../actions'
import { deleteAssessment, getCourses, getAssessments } from 'common/actions/course'

import Loading from './Loading'
import ViewToggle from './ViewToggle'
import CourseCard from './CourseCard'
import CourseListItem from './CourseListItem'

import _ from 'lodash'

const mapStateToProps = (state, ownProps) => {

  let assessments
  if (ownProps.currentPage === 'feedback') {
    assessments = state.assessments.assessmentData.filter(
      assessment => assessment.attributes.field_status === 'in_progress'
    ).filter(assessment => assessment.attributes.field_comments)  
  }
  else {
    assessments = state.assessments.assessmentData.filter(
      assessment => assessment.attributes.field_status === ownProps.currentPage
    ).filter(assessment => !assessment.attributes.field_comments)  
  }
 
  return {
    session: state.session,
    courseData: state.courses.courseData,
    courses: state.courses,
    categories: state.categories,
    assessmentsFetched: state.assessments.fetched,
    assessments: _.orderBy(assessments, 'attributes.changed', 'desc'),
    viewStyle: state.viewStyle
  }
}

const mapDispatchToProps = {
  deleteAssessment,
  getAssessments,
  getCourses,
  changeNavbar
}

class InProgress extends Component {

  state = {
    fetched: false
  }

  componentDidMount() {
    this.fetchAssessmentData()
    this.props.changeNavbar('in-progress')
  }

  removeAssessment = async (id) => {
    await this.props.deleteAssessment(id, this.props.session)
    await this.props.getAssessments()
  }

  fetchAssessmentData = async (refresh) => {
    this.setState({ fetched: false })
    if (refresh || !this.props.courses.fetched) {
      await this.props.getCourses(this.props.session.trial)
    }
    await this.props.getAssessments()
    this.setState({ fetched: true })
  }

  render() {
    const { currentPage, assessments, courseData, categories, viewStyle } = this.props

    const { fetched } = this.state
    let content, card
    let statusText, loadingMessage

    switch(currentPage) {
      case 'saved_for_later':
        statusText = <>You don't have any micro-courses saved. Click <strong>Save for Later</strong> when viewing a course page to pin it here.</>
        loadingMessage = 'Getting Saved Micro-Courses...'
        break;
      case 'feedback':
        statusText = <>No feedback yet. Submit a challenge!</>
        loadingMessage = 'Getting Reviewed Challenges...'
        break;
      case 'submitted':
        statusText = <>You haven't submitted any challenges yet. Let's go!</>
        loadingMessage = 'Getting Submitted Challenges...'
        break;
      default:
        statusText = <>You don't have any outstanding challenges — time to get started!</>
        loadingMessage = 'Getting Challenges...'
    }

    if (fetched && assessments) {

      content = <>
        {
          assessments.length > 0 && assessments.map((assessment, index) => {
            let targetCourse = courseData.find((course) => {
              return course.id === assessment.relationships.field_course.data.id
            })

            if (targetCourse) {
              let category = categories.find(function(cat) {
                return cat.id === targetCourse.relationships.field_category.data.id
              })

              let secondaryCategories = targetCourse.relationships.field_secondary_category.data.map((secondaryCategory) => {
                return categories.find(cat => cat.id === secondaryCategory.id)
              })  
  
              card = <CourseCard
                animationDelay={index * .05 + 's'}
                key={assessment.id}
                course={targetCourse}
                assessment={assessment}
                category={category}
                secondaryCategories={secondaryCategories}
                status={assessment.attributes.field_status}
                currentPage={currentPage}
                removeAssessment={this.removeAssessment}
              />
        
              if (viewStyle === 'list') {
                card = <CourseListItem
                  animationDelay={index * .05 + 's'}
                  key={assessment.id}
                  course={targetCourse}
                  assessment={assessment}
                  category={category}
                  secondaryCategories={secondaryCategories}
                  status={assessment.attributes.field_status}
                  currentPage={currentPage}
                  removeAssessment={this.removeAssessment}
                />
              }
            }
            return card
          })
        }
      </>
    }

    return (
      <div className="page in-progress">

        <ViewToggle />
        <Button onClick={() => { this.fetchAssessmentData(true) }} className="button small refresh">
          <span className="icon" />
          Refresh
        </Button>

        {
          fetched ?
          <>
            {
              viewStyle === 'grid' ?
              <div className="course-library course-grid">
                <Grid container spacing={3}>
                  {content}
                </Grid>
              </div>
              :
              <ul className="course-library course-list">
                {content}
              </ul>
            }
          </>
          :
          <Loading message={loadingMessage} />
        }
        {
          fetched && !assessments.length &&
          <p className="statusText">
            {statusText}
          </p>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InProgress);