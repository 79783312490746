import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

// import { filterCourses } from '../actions'
import {
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button,
  InputLabel
} from '@material-ui/core'

import { isMobile } from 'react-device-detect'
import classNames from 'classnames'

import '../styles/sidebar.scss'
import { updateFilters } from '../actions'

const mapStateToProps = ({session, categories, sidebar}, ownProps) => {

  let sortedCategories = _.sortBy(categories, (category) => {
    return category.attributes.name
  }).filter((category) => {
    // Filter out excluded categories if we have a group
    if (session.excludedCategories) {
      return !session.excludedCategories.find(cat => cat.target_id === category.attributes.drupal_internal__tid)
    }
    else {
      return category
    }
  })

  return {
    sortedCategories: sortedCategories,
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: sidebar.pages.achievements.items.find(item => item.id === 'completed').filters
  }
}

const mapDispatchToProps = {
  updateFilters
}

class CoursesCompletedFilters extends Component {

  updateFilters = (event, key) => {
    let filters = { ...this.props.filters }
    filters[key] = event.target.value
    this.props.updateFilters(filters, 'completed')
  }

  resetFilters = () => {
    this.props.updateFilters({
      searchQuery: '',
      category: 'any',
      sort: 'dateNewest'
    }, 'completed')
  }

  toggleExpand = () => {
    this.props.updateFilters(this.props.isExpanded ? false : true, 'mobile')
  }

  render() {
    const { sort, searchQuery, category } = this.props.filters

    return (
      <form className={classNames('filters coursesComplete', this.props.sidebar.isCollapsed && 'collapsed', isMobile && this.props.isExpanded && 'expanded')}>
        <header>
          <strong>Filter Courses</strong>
          <Button className="button small" onClick={this.resetFilters}>Reset</Button>
        </header>
        <FormControl>

        <div className="flexRow inputSearch">
          <span className="icon search"/>
          <TextField 
            variant="standard"
            label="Search"
            value={searchQuery}
            onChange={(event) => { this.updateFilters(event, 'searchQuery') }}
          />
        </div>
        </FormControl>
 
        <FormControl className="inputSelect category">
          <span className="icon category"/>
          <InputLabel id="label-category-select">Category</InputLabel>
          <Select
            labelId="label-category-select"
            id="category-select"
            value={category}
            onChange={(event) => { this.updateFilters(event, 'category') }}>
            <MenuItem key={'any'} value={'any'}>Any</MenuItem>
            {
              this.props.sortedCategories && this.props.sortedCategories.map(category => {
                return (
                  <MenuItem key={category.id} value={category.id}>{category.attributes.name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <header style={{ marginTop: 25 }}>
          <strong>Sort Courses</strong>
        </header>

        <FormControl>
          <InputLabel>Sort By</InputLabel>
          <Select value={sort ? sort : 'dateNewest'} onChange={(event) => { this.updateFilters(event, 'sort') }}>
            <MenuItem value={'dateNewest'}>Date Completed (Newest First)</MenuItem>
            <MenuItem value={'dateOldest'}>Date Completed (Oldest First)</MenuItem>
            <MenuItem value={'name'}>Course Name</MenuItem>
          </Select>
        </FormControl>

        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon"/>
        </div>

      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesCompletedFilters);