import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { Menu, MenuItem, Button } from '@material-ui/core'
import { getUserLearningPaths, getAssessments, getLearningPaths, getCourses, getBadges } from 'common/actions/course'
import { setNotificationsFetched } from 'common/actions/user'

import { getNotifications, deleteNotification, setSidebar, getUserBadges } from '../actions'
import { generateTimestamp } from '../functions'
import NotificationPopup from './NotificationPopup'

import '../styles/navbar.scss'

const mapStateToProps = ({ notifications, session, courses, assessments, learningPaths, badges, userBadges }, ownProps) => {

  let notificationData = [...notifications]

  notificationData.forEach((notification) => {
    let type = notification.attributes.field_notification_type
    if (type === 'assessment_complete' || type === 'assessment_feedback') {
      let targetAssessment = assessments.assessmentData.find(assessment => assessment.id === notification.relationships.field_assessment.data.id)
      notification.course = targetAssessment && courses.courseData.find(course => course.id === targetAssessment.relationships.field_course.data.id)
    }
    if (type === 'user_badge_created') {
      let newUserBadge = session.userBadges && session.userBadges.find(userBadge => userBadge.id === notification.relationships.field_user_badge.data.id)
      let newBadge = newUserBadge && badges.badgeData.find(badge => badge.id === newUserBadge.relationships.field_badge.data.id)
      notification.badge = newBadge
    }
    if (type === 'ulp_complete') {
      let newUserLearningPath = session.userLearningPaths && session.userLearningPaths.find(userPath => userPath.id === notification.relationships.field_user_learning_path.data.id)
      notification.learningPath = newUserLearningPath && learningPaths.paths.find(path => path.id === newUserLearningPath.relationships.field_learning_path.data.id)
    }
  })

  notificationData.reverse()

  return {
    notifications: notificationData,
    session,
    courses,
    assessments,
    learningPaths,
    badges,
    userBadges
  }
}

const mapDispatchToProps = {
  getNotifications,
  getUserBadges,
  getUserLearningPaths,
  getAssessments,
  getLearningPaths,
  getCourses,
  getBadges,
  setSidebar,
  deleteNotification,
  setNotificationsFetched
}

class NotificationsMenu extends Component {

  state = {
    anchorEl: null,
    hasOpened: false,
    showBadgeDialog: false,
    seen: [],
    removed: []
  }

  componentDidMount = () => {
    const { session, badges, courses, learningPaths } = this.props

    if (!badges.fetched) {
      this.props.getBadges()
    }
    if (!session.fetched.userBadges) {
      this.props.getUserBadges(this.props.session.user)
    }
    if (!session.fetched.userLearningPaths) {
      this.props.getUserLearningPaths(this.props.session.user)
    }
    if (!courses.fetched) {
      this.props.getCourses(this.props.session.trial)
    }
    if (!learningPaths.fetched) {
      this.props.getLearningPaths()
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, hasOpened: true })
    this.props.fetchNotifications()
  }

  handleClose = (badge) => {
    this.setState({ anchorEl: null })

    // Mark notifications as seen
    this.props.setNotificationsFetched(this.props.session.user)

    this.props.notifications.forEach((notification) => {
      setTimeout(() => {
        let seen = [...this.state.seen]
        seen.push(notification.id)
        this.setState({ seen })
      }, 750)
    })
    if (badge) {
      this.props.setSidebar('achievements', 'badgesEarned')
    }
  }

  delete = (notification) => {
    this.props.deleteNotification(notification)
    let removed = [...this.state.removed]
    removed.push(notification.id)
    if (removed.length === this.props.notifications.length) {
      this.setState({ anchorEl: null })
    }
    this.setState({ removed })
  }

  deleteAll = () => {
    let removed = [...this.state.removed]
    this.setState({ anchorEl: null })

    setTimeout(() => {
      this.props.notifications.forEach((notification) => {
        removed.push(notification.id)
        this.props.deleteNotification(notification)
      })
      this.setState({ removed })
    }, 1200)
  }

  render() {
    const { notifications, session } = this.props
    const { anchorEl, removed } = this.state
    let lastFetched = session.user.attributes.field_notifications_last_fetched

    let unseenCount = notifications.filter(notification =>
      !lastFetched || moment(lastFetched).isBefore(notification.attributes.created)
    ).length

    let isEmpty = !this.props.notifications.length || (removed.length === this.props.notifications.length)
    let notificationsReady = unseenCount > 0 && notifications.length && (notifications[0].badge || notifications[0].learningPath || notifications[0].course)

    return (
      <div>
        {
          notificationsReady &&
          <NotificationPopup session={this.props.session} router={this.props.router} notification={notifications[0]} />
        }

        <div onClick={this.handleClick} className={classNames('notifications', notificationsReady && 'swing')}>
          {
            unseenCount > 0 &&
            <div className={classNames('count', this.state.hasOpened && 'fade')}>
              {unseenCount}
            </div>
          }
        </div>

        <Menu id="notification-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <ul className={classNames('notifications-list', { 'empty': isEmpty })}>
            {
              notifications.map((notification) => {
                let seen = moment(lastFetched).isAfter(notification.attributes.created) || this.state.seen.includes(notification.id)
                let path, content
                let type = notification.attributes.field_notification_type

                if (notification.course) {
                  path = "/course/" + notification.course.attributes.drupal_internal__id
                  if (type === 'assessment_complete') {
                    content = <>You completed <strong>{notification.course.attributes.name}</strong>!</>
                  }
                  if (type === 'assessment_feedback') {
                    content = <>Feedback available for <strong>{notification.course.attributes.name}</strong>.</>
                  }
                }
                if (notification.badge) {
                  path = "/achievements"
                  content = <>You earned the <strong>{notification.badge.attributes.name}</strong> badge!</>
                }

                if (notification.learningPath) {
                  path = "/learning-path/" + notification.learningPath.attributes.drupal_internal__id
                  content = <>You completed the <strong>{notification.learningPath.attributes.name}</strong> learning path!</>
                }

                return (
                  <MenuItem key={notification.id}>
                    <Link onClick={() => { this.handleClose(notification.badge) }} className={classNames({ 'new': !seen }, { 'removed': removed.includes(notification.id) })} to={path}>
                      <div className={classNames('icon', notification.attributes.field_notification_type)} />
                      <div className="content">
                        <div>{content}</div>
                        <div className="timestamp">{generateTimestamp(notification.attributes.created)}</div>
                      </div>
                    </Link>
                    <div onClick={() => { this.delete(notification) }} className="icon remove" />
                  </MenuItem>
                )
              })
            }
          </ul>
          {
            isEmpty ?
              <div className="message-empty">You have no new notifications.</div>
              :
              <footer>
                <Button onClick={this.deleteAll} className="button small">Clear All Notifications</Button>
              </footer>
          }
        </Menu>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMenu);
