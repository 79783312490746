import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateProfile, getUser } from 'common/actions/user'
import { changeNavbar } from '../actions'
import { TextField, Select, Button, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import classNames from 'classnames'
import Loading from './Loading'

import { getJobTitles } from '../actions'

import ProfilePicture from './ProfilePicture'
import '../styles/profile.scss'

const mapStateToProps = ({ jobTitles, session }, ownProps) => {

  return {
    session,
    jobTitles: jobTitles.filter((title) => {
      return title.attributes.name !== 'School' && title.attributes.name !== 'District'
    }),
    user: session.user
  }
}

const mapDispatchToProps = {
  updateProfile,
  getJobTitles,
  getUser,
  changeNavbar
}

class ProfileLayout extends Component {

  constructor(props) {
    super(props);
    let jobTitleOther = this.props.user.attributes.field_job_title_other

    this.state = {
      jobTitle: this.props.user.jobTitle && this.props.user.jobTitle.id,
      jobTitleOther: jobTitleOther ? jobTitleOther : '',
      isOther: jobTitleOther ? true : false,
      name: this.props.user.attributes.field_name,
      isSaving: false,
    }

    this.updateName = this.updateName.bind(this);
  }

  componentDidMount = () => {
    this.props.changeNavbar(null)
    this.props.getJobTitles()
  }

  updateName = (event) => {
    const { user } = this.props
    let hasChanged = user.jobTitle && (event.target.value !== user.attributes.field_name || user.jobTitle.id !== this.state.jobTitle)
    this.setState({ name: event.target.value, hasChanged: hasChanged })
  }

  updateJobTitle = (event) => {
    const { user, jobTitles } = this.props
    let hasChanged = user.jobTitle && (user.jobTitle.id !== event.target.value || user.attributes.field_name !== this.state.name)

    let jobTitle = jobTitles.find(title => title.id === event.target.value)
    if (jobTitle.attributes.name.includes('Other')) {
      this.setState({ jobTitle: event.target.value, isOther: true })
    }
    else {
      this.setState({ jobTitle: event.target.value, jobTitleOther: '', isOther: false, hasChanged: hasChanged })
    }
  }

  updateJobTitleOther = (event) => {
    this.setState({ jobTitleOther: event.target.value, hasChanged: true })
  }

  save = async () => {
    this.setState({ isSaving: true })

    let profile = {
      name: this.state.name,
      jobTitle: this.state.jobTitle,
      jobTitleOther: this.state.jobTitleOther
    }

    Promise.all([
      this.props.updateProfile(profile, this.props.session),
      this.props.getUser(this.props.session.user.attributes.drupal_internal__uid)
    ]).then(() => {
      this.setState({ isSaving: false, hasChanged: false, isSaved: true })

      setTimeout(() => {
        this.setState({ isSaved: false })
      }, 1800)
    })
  }

  render() {
    const { name, jobTitle, isSaving, isSaved, hasChanged } = this.state
    let locked = isSaving || isSaved

    return (
      <div className="page profile">
        <div className={classNames('flexRow profileWrapper', locked && 'locked')}>
          <ProfilePicture />
          <div className="profileContent">
            <h2>Your Profile</h2>
            <div className="profileForm">
              <FormControl>
                <TextField
                  variant="standard"
                  label="Name"
                  value={name}
                  onChange={this.updateName}
                />
              </FormControl>
              <FormControl className="inputSelect">
                <InputLabel id="label-job-title-select">Job Title</InputLabel>
                <Select
                  labelId="label-job-title-select"
                  id="job-title-select"
                  value={jobTitle}
                  onChange={this.updateJobTitle}>
                  {
                    this.props.jobTitles && this.props.jobTitles.map(jobTitle => {
                      return (
                        <MenuItem key={jobTitle.id} value={jobTitle.id}>{jobTitle.attributes.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
              {
                this.state.isOther &&
                <FormControl>
                  <TextField
                    variant="standard"
                    label="Please specify your job title"
                    value={this.state.jobTitleOther}
                    onChange={this.updateJobTitleOther}
                  />
                </FormControl>
              }
              <footer>
                {
                  hasChanged &&
                  <Button onClick={this.save} className={'button primary large'}>Save Profile</Button>
                }
              </footer>
            </div>
          </div>
          {isSaving && <Loading message="Saving Profile..." />}
          {isSaved && <div className="saved"> <span className="icon check"></span>Profile Saved</div>}
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout);
