import React, { Component } from 'react'
import { connect } from 'react-redux'

import CourseListItemCompleted from './CourseListItemCompleted'
import CoursesCompletedFilters from './CoursesCompletedFilters'
import ViewToggle from './ViewToggle'
import CourseCard from './CourseCard'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { Grid } from '@material-ui/core'
import { isMobileOnly } from 'react-device-detect'

const mapStateToProps = (state, ownProps) => {

  let sortedCategories = _.sortBy(state.categories, (category) => {
    return category.attributes.name
  })

  return {
    session: state.session,
    sidebar: state.sidebar,
    isExpanded: state.sidebar.mobileFiltersExpanded,
    filters: state.sidebar.pages.achievements.items.find(item => item.id === 'completed').filters,
    completedAssessments: state.assessments.assessmentData.filter(assessment => assessment.attributes.field_status === 'completed'),  
    assessments: state.assessments.assessmentData,
    categories: sortedCategories,
    courses: state.courses.courseData,
    viewStyle: state.viewStyle
  }
}

// @todo - Now that there are only minor differences between this and the ExploreLayout, perhaps we combine them into one component? -CR
class CoursesCompletedLayout extends Component {

  componentDidMount = () => {
    window.scrollTo(0,0)
  }
  
  filterByQuery = (assessment) => {
    let { filters } = this.props
    let course = this.getCourseFromAssessment(assessment)
    if (course.attributes.name.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) > -1) {
      return assessment
    }
  }

  filterByCategory = (assessment) => {
    let { filters } = this.props
    let hasCategory

    let course = this.getCourseFromAssessment(assessment)
    let categories = course.relationships.field_secondary_category.data.map(category => category.id)
    categories.push(course.relationships.field_category.data.id)

    categories.forEach((category) => {
      if (category === filters.category) {
        hasCategory = true
      } 
    })
    return hasCategory && assessment
  }

  filterAssessments = (assessment) => {

    let { filters } = this.props

    let byQuery = filters.searchQuery.length > 0 ? this.filterByQuery(assessment) : assessment
    if (!byQuery) {
      return false
    }
    else {
      let byCategory = filters.category !== 'any' ? this.filterByCategory(byQuery) : byQuery
      if (!byCategory) {
        return false
      }
      else {
        return byCategory
      }
    }

  }

  getCourseFromAssessment = (assessment) => {
    return this.props.courses.find(course => assessment.relationships.field_course.data.id === course.id)
  }
  
  render() {

    const { completedAssessments, categories, filters, viewStyle } = this.props

    let sortedAssessments = completedAssessments && _.orderBy(completedAssessments, (assessment) => {
      if (filters.sort === 'name') {
        return this.getCourseFromAssessment(assessment).attributes.name
      }
      else {
        return moment(assessment.attributes.changed).format('YYYY-MM-DD HH:MM:SS')
      }
    }, filters.sort === 'dateNewest' && ['desc'])
    
    let content = <>
      {
        sortedAssessments && sortedAssessments.filter(this.filterAssessments).map((assessment, index) => {

          let course = this.getCourseFromAssessment(assessment)
          let category = categories.find(cat => cat.id === course.relationships.field_category.data.id)
          let secondaryCategories = course.relationships.field_secondary_category.data.map((secondaryCategory) => {
            return categories.find(cat => cat.id === secondaryCategory.id)
          })

          if (viewStyle === 'grid') {
            return (
              <CourseCard
                animationDelay={index * .05 + 's'}
                key={assessment.id}
                course={course}
                assessment={assessment}
                category={category}
                secondaryCategories={secondaryCategories}
                status={assessment && assessment.attributes.field_status}
                currentPage="achievements"
              />
            )
          }
          else {
            return (
              <CourseListItemCompleted
                animationDelay={index * .05 + 's'}
                key={assessment.id}
                assessment={assessment}
                course={course}
                category={category}
                currentPage="achievements"
              />                
            )  
          }

        })
      }   
    </>
      
    return (
      <div className="page completedCourses">
        <ViewToggle />             
        <div className="itemCount">
          <span>{completedAssessments.filter(this.filterAssessments).length}</span>
          {
            completedAssessments.filter(this.filterAssessments).length === 1 ?
            <>Completed Micro-Course</>
            :
            <>Completed Micro-Courses</>
          }
        </div>

        {
          isMobileOnly &&
          <div className="mobileFilters">
            <CoursesCompletedFilters />
          </div>
        }

        {
          viewStyle === 'grid' ?
          <div className={classNames('course-library course-grid', isMobileOnly && 'mobile-list', isMobileOnly && this.props.isExpanded && 'isExpanded')}>
            <Grid container spacing={3}>
              {content}
            </Grid>
          </div>
          :
          <ul className={classNames('course-library course-list', isMobileOnly && 'mobile-list', isMobileOnly && this.props.isExpanded && 'isExpanded')}>
            {content}
          </ul>
        }

      </div>
    )
  }
}

export default connect(mapStateToProps)(CoursesCompletedLayout);