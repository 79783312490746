/* global chrome */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { changeNavbar, createAssessment, collapseSidebar } from '../actions'
import { getBadges, getCourses, getAssessments, getUserLearningPaths, getLearningPaths, updateAssessment, deleteAssessment } from 'common/actions/course'
import { generateTimestamp, getActiveUserLearningPath } from '../functions'
import classNames from 'classnames'
import { isMobile, isChrome } from 'react-device-detect'
import Sidebar from './Sidebar'
import SanitizedHTML from './SanitizedHTML'

import '../styles/course-single.scss'

const mapStateToProps = ({ session, extension, courses, badges, assessments, categories, learningPaths, }, ownProps) => {

  var activeCourse = courses.courseData.find((course) => {
    return course.attributes.drupal_internal__id === Number(ownProps.params.courseId)
  })

  let courseBadges = badges.badgeData.filter((badge) => {
    return activeCourse && badge.relationships.field_course.data.find(course => course.id === activeCourse.id)
  })

  let allLearningPaths = { ...learningPaths }

  // Get the most recently updated User Learning Paths that's still in progress
  let activeUserLearningPath = session.userLearningPaths && activeCourse && getActiveUserLearningPath(session.userLearningPaths, allLearningPaths, activeCourse)

  let startedAssessment = activeCourse && assessments.assessmentData && assessments.assessmentData.find(
    assessment => assessment.relationships.field_course.data.id === activeCourse.id
  )

  let assessmentIsSaved = startedAssessment && startedAssessment.attributes.field_status === "saved_for_later"

  return {
    courses,
    session,
    extension,
    categories,
    assessments,
    assessment: startedAssessment,
    assessmentIsSaved,
    learningPaths,
    activeUserLearningPath,
    activeCourse,
    courseBadges,
    badges
  }
}

const mapDispatchToProps = {
  changeNavbar,
  getCourses,
  getAssessments,
  deleteAssessment,
  getLearningPaths,
  getBadges,
  createAssessment,
  updateAssessment,
  generateTimestamp,
  getUserLearningPaths,
  collapseSidebar
}

class CourseLayout extends Component {

  state = {
    assessmentStarted: false,
    showSaveButton: true
  }

  componentDidMount = () => {
    const { courses, learningPaths, session, badges } = this.props

    this.props.changeNavbar('learn')
    if (!isMobile || window.innerWidth > 667) {
      this.props.collapseSidebar(true)
    }

    !courses.fetched && this.props.getCourses(this.props.session.trial)
    !learningPaths.fetched && this.props.getLearningPaths()
    !session.fetched.userLearningPaths && this.props.getUserLearningPaths(this.props.session.user)
    !badges.fetched && this.props.getBadges()

  }

  resumeAssessment = () => {
    const { activeUserLearningPath, activeCourse, learningPaths } = this.props

    !isMobile && isChrome && chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      type: 'startAssessment',
      assessmentId: this.props.assessment.id,
      learningPaths: learningPaths,
      activeCourse: activeCourse,
      userLearningPath: activeUserLearningPath
    })
  }

  startAssessment = async () => {
    const { activeUserLearningPath, activeCourse, learningPaths } = this.props
    this.setState({ assessmentStarted: true })

    let assessmentId
    if (!this.props.assessmentIsSaved) {
      let newAssessment = await this.props.createAssessment(this.props.session, this.props.activeCourse)
      assessmentId = newAssessment.data.id
    }
    else {
      this.setState({ isSaved: false })
      assessmentId = this.props.assessment.id
      await this.props.updateAssessment(assessmentId, this.props.session, 'in_progress')
    }

    // Send the message to the extension
    !isMobile && isChrome && chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      type: 'startAssessment',
      assessmentId: assessmentId,
      learningPaths: learningPaths,
      activeCourse: activeCourse,
      userLearningPath: activeUserLearningPath
    })

    this.props.getAssessments()
  }

  goBack = () => {
    if (this.props.router.location.action === 'PUSH') {
      this.props.router.goBack()
    }
    else {
      this.props.router.push('/')
    }
  }

  save = async () => {
    this.setState({ isSaved: true, saveButtonClicked: true })
    await this.props.createAssessment(this.props.session, this.props.activeCourse, 'saved_for_later')
    this.props.getAssessments()
  }

  unsave = async () => {
    this.setState({ isSaved: false, saveButtonClicked: false })
    if (this.props.assessment.id) {
      await this.props.deleteAssessment(this.props.assessment.id, this.props.session)
      this.props.getAssessments()
    }
  }

  render() {

    const { categories, activeCourse, courseBadges, assessment, assessmentIsSaved } = this.props
    let isSubmitted = false, isCompleted = false, audioContent, showAsSaved

    if (activeCourse) {
      // Get category and skill level information
      let category = categories.find(category => activeCourse.relationships.field_category.data.id === category.id)
      let categoryLabel = category && category.attributes.name.toLowerCase()
      let courseLevel = activeCourse.attributes.field_level.toLowerCase()

      let secondaryCategories = activeCourse.relationships.field_secondary_category.data.map((secondaryCategory) => {
        return categories.find(cat => cat.id === secondaryCategory.id)
      })

      let showSaveButton = this.props.session.fetched.userLearningPaths && (!assessmentIsSaved || this.state.saveButtonClicked === false)
      if (assessment) {
        if (assessmentIsSaved && !this.state.assessmentStarted) {
          showAsSaved = !showSaveButton
        }
        else {
          showAsSaved = false
        }
      }
      else {
        showAsSaved = this.state.saveButtonClicked
      }

      let showStatus = (assessment && !assessmentIsSaved) || this.state.assessmentStarted

      // Find out if we have started this course already
      let updated = assessment && generateTimestamp(assessment.attributes.changed)

      // Figure out what the status of the assessment means for us
      let assessmentStatus = assessment && assessment.attributes.field_status
      if (assessmentStatus) {
        isSubmitted = assessmentStatus === 'submitted' || assessmentStatus === 'grading_in_progress'
        isCompleted = assessmentStatus === 'completed'
      }

      // Handle any audio links
      if (activeCourse.attributes.field_audio_link) {
        let audio = activeCourse.attributes.field_audio_link
        let audioId = audio.replace('https://drive.google.com/file/d/', '').replace('/view', '')
        let audioUrl = 'https://drive.google.com/uc?export=download&id=' + audioId

        audioContent = <audio controls>
          <source src={audioUrl} />
        </audio>
      }

      // Determine if we should allow them to start
      let showStartButton = this.props.session.fetched.userLearningPaths && (!assessment || assessmentIsSaved)

      let sidebarContent = <div className="entity-meta">
        <Button className="backButton" onClick={this.goBack}>
          <span className="icon back"></span>
          <span className="buttonText">Back</span>
        </Button>

        <span className={classNames('category-icon large', categoryLabel)}></span>
        <h3>Micro-Course</h3>
        {
          secondaryCategories && secondaryCategories.length > 0 ?
            <header>Categories</header>
            :
            <header>Category</header>
        }
        <div className='category-icon-wrapper'>
          <span className={classNames('category-icon', categoryLabel)}></span>
          {categoryLabel}
        </div>

        {
          secondaryCategories && secondaryCategories.length > 0 && secondaryCategories.map((category) => {
            let secondaryLabel = category.attributes.name.toLowerCase()
            return (
              <div key={category.id} className='category-icon-wrapper'>
                <span className={classNames('category-icon', secondaryLabel)}></span>
                {secondaryLabel}
              </div>
            )
          })
        }

        <header>Skill Level</header>
        <div className="course-level">
          <span className="icon star"></span>
          {courseLevel === 'intermediate' && <span className="icon star"></span>}
          {courseLevel === 'advanced' &&
            <>
              <span className="icon star"></span>
              <span className="icon star"></span>
            </>
          }
        </div>

        {/* {
          activeCourse.attributes.field_pd_credits_integer &&
          <>
            <header>PD Credits</header>
            <div className="flexRow course-pd">
              <span className="icon pd-credits" />
              <span className="count">{activeCourse.attributes.field_pd_credits_integer}</span>
            </div>
          </>
        } */}


        {
          courseBadges && courseBadges.length > 0 &&
          <>
            <header>Badge Opportunities</header>
            <ul>
              {
                courseBadges.map((badge) => {
                  return (
                    <li key={badge.id}>
                      <span className="icon badge" />
                      <span className="itemLabel">{badge.attributes.name}</span>
                    </li>
                  )
                })
              }
            </ul>
          </>
        }
      </div>

      return (
        <>
          <Sidebar currentPage="course" content={sidebarContent} />
          <div className="page course-single">
            <h1>{activeCourse.attributes.name}</h1>
            {
              (!assessmentStatus && !this.state.assessmentStarted) &&
              <Button style={{ marginLeft: isMobile && 0 }} className={classNames('button small save', (!showSaveButton || this.state.saveButtonClicked || this.state.assessmentStarted) && 'fade')} onClick={() => this.save()}>
                <span className="icon pin"></span>Save for Later
              </Button>
            }
            {
              showStatus &&
              <>
                <div className={classNames('assessmentStatus', isCompleted && 'isCompleted', isSubmitted && 'isSubmitted', assessment && !isSubmitted && !isCompleted && 'started', this.state.assessmentStarted && 'new')}>
                  <div className="flexRow">
                    {isCompleted && <p className="status"><span className="icon completed"></span>Completed {updated}</p>}
                    {isSubmitted && <p className="status"><span className="icon submitted"></span>Submitted {updated}</p>}
                    {
                      showStatus && !isSubmitted && !isCompleted &&
                      <p className="status"><span className="icon started"></span>
                        {
                          this.state.assessmentStarted && !assessmentStatus &&
                          <>Starting Challenge...</>
                        }
                        {
                          assessmentStatus &&
                          <>Started {updated} </>
                        }
                      </p>
                    }

                    { // We can't allow users to manipulate assessments outside of Chrome or without the extension
                      !isMobile && isChrome && this.props.extension.version && assessmentStatus && assessmentStatus === "in_progress" && this.props.session.fetched.userLearningPaths &&
                      <Button onClick={this.resumeAssessment} className="button small">Resume Challenge</Button>
                    }
                  </div>
                  {
                    assessmentStatus && assessment.attributes.field_comments &&
                    <p className="feedback">"{assessment.attributes.field_comments}"</p>
                  }
                </div>
              </>
            }
            {
              showAsSaved &&
              <Button onClick={this.unsave} className="button small unsave">
                <span className="icon pin" />Unsave
              </Button>
            }

            <h3>What You'll Learn</h3>
            {
              activeCourse.attributes.field_course_objective &&
              <SanitizedHTML html={activeCourse.attributes.field_course_objective} />
            }
            <h3>What You'll Do</h3>
            {
              activeCourse.attributes.field_course_content &&
              <SanitizedHTML html={activeCourse.attributes.field_course_content} />
            }
            {
              activeCourse.attributes.field_video_link &&
              <div className="videoWrapper">
                <iframe
                  title="Course Video"
                  width="675"
                  height="380"
                  src={activeCourse.attributes.field_video_link}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen={true}>
                </iframe>
              </div>
            }
            {
              activeCourse.attributes.field_audio_link &&
              <>{audioContent}</>
            }
            {
              showStartButton && this.props.extension.version &&
              <Button id="startAssessment" className={classNames('button large primary', this.state.assessmentStarted && 'started')} onClick={this.startAssessment}>
                <span className="icon rocket"></span>
                Start Challenge
              </Button>
            }
            {
              activeCourse.attributes.field_course_additional_resource &&
              <>
                <h3>Additional Resources</h3>
                <SanitizedHTML html={activeCourse.attributes.field_course_additional_resource} />
              </>
            }
          </div>
        </>
      )
    }
    else {
      return null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseLayout);
