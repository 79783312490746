import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Masonry from 'react-masonry-component'

import WidgetCourseList from './WidgetCourseList'
import WidgetBadge from './WidgetBadge'
import WidgetAchievements from './WidgetAchievements'
import WidgetSocial from './WidgetSocial'
import WidgetSupport from './WidgetSupport'
import WidgetLearningPath from './WidgetLearningPath'
import WidgetAnnouncement from './WidgetAnnouncement'
import WidgetFeedback from './WidgetFeedback'

import { startProductTour } from '../actions'
import { isCourseCategoryExcluded } from '../functions'

import '../styles/dashboard.scss'

const mapStateToProps = ({ session, courses, assessments, productTour, categories, learningPaths, announcements, sidebar }) => {

  // Get everything together to construct our widgets
  let sortedAssessments = assessments.assessmentData && _.orderBy(assessments.assessmentData, (assessment) => {
    return moment(assessment.attributes.changed).format('YYYY-MM-DD HH:MM:SS')
  })

  let coursesInProgress = sortedAssessments.filter(assessment => assessment.attributes.field_status === 'in_progress')
    .filter(assessment => !assessment.attributes.field_comments)

  let coursesWithFeedback = sortedAssessments.filter(assessment => assessment.attributes.field_status === 'in_progress')
    .filter(assessment => assessment.attributes.field_comments)

  let coursesSaved = sortedAssessments.filter(assessment => assessment.attributes.field_status === 'saved_for_later')
  let coursesCompleted = sortedAssessments.filter(assessment => assessment.attributes.field_status === 'completed')

  let userBadges = session.userBadges && _.orderBy(session.userBadges, (badge) => {
    return badge.attributes.created
  }, ['desc'])

  let mostRecentUserBadge = userBadges && userBadges.length && userBadges[0]

  // Limit User Learning Paths to paths that haven't been excluded, just in case
  let availableUserLearningPaths = session.userLearningPaths.filter(userPath => learningPaths.paths.find(path => path.id === userPath.relationships.field_learning_path.data.id))

  let learningPathsInProgress = availableUserLearningPaths && _.orderBy(
    availableUserLearningPaths.filter(path => path.attributes.field_status === "in_progress"
    ), (path => path.attributes.changed), ['desc'])

  let inProgressLearningPath = learningPathsInProgress[0]

  let activeAnnouncements = announcements.data.filter((announcement) => {
    return announcement.relationships.field_announcement_type.data
  })

  let sortedAnnouncements = _.orderBy(activeAnnouncements, (announcement => announcement.attributes.created), ['desc'])

  // Build out widgets
  let feedbackWidget = {
    label: 'Feedback',
    type: 'feedback',
    targetRoute: 'in-progress',
    targetPage: 'inProgress',
    targetTab: 'feedback',
    assessment: coursesWithFeedback[0]
  }

  let inProgressWidget = {
    label: 'Jump Back In',
    type: 'course-list',
    targetRoute: 'in-progress',
    targetPage: 'inProgress',
    targetTab: 'in_progress',
    assessments: coursesInProgress
  }

  let savedForLaterWidget = {
    label: 'Ready for Launch',
    type: 'course-list',
    targetRoute: 'in-progress',
    targetPage: 'inProgress',
    targetTab: 'saved_for_later',
    assessments: coursesSaved
  }

  let learningPathWidgetInProgress = {
    label: 'Next Steps',
    type: 'learningPath',
    status: 'in_progress',
    targetTab: 'learningPaths',
    userLearningPath: inProgressLearningPath
  }

  let badgeWidget = {
    label: 'Keep at it',
    type: 'badge',
    targetTab: 'badges',
    userBadge: mostRecentUserBadge
  }

  let achievementsWidget = {
    label: 'Way to Go',
    title: 'Your Achievements',
    type: 'achievements',
    targetRoute: 'achievements',
  }

  let socialWidget = {
    label: 'Get Social',
    type: 'social',
    links: [
      {
        iconClasses: 'icon twitter',
        name: 'Twitter',
        href: 'https://twitter.com/mobilemindedu'
      },
      {
        iconClasses: 'icon facebook',
        name: 'Facebook',
        href: 'https://www.facebook.com/mobilemindedu/'
      },
      {
        iconClasses: 'icon linkedIn',
        name: 'LinkedIn',
        href: 'https://linkedin.com/company/mobilemind-/'
      },
    ]
  }

  let widgets = []

  // Important Announcements
  activeAnnouncements.forEach((announcement) => {
    let type = announcements.types.find(type => type.id === announcement.relationships.field_announcement_type.data.id)
    if (type.attributes.name === "Important") {
      let image, video
      if (announcement.relationships.field_media.data) {
        image = announcements.images.find(image => image.id === announcement.relationships.field_media.data.id)
      }
      if (announcement.relationships.field_video.data) {
        video = announcements.videos.find(image => image.id === announcement.relationships.field_video.data.id)
      }
      let widget = {
        type: 'announcement',
        announcement: announcement,
        image: image && image,
        video: video && video,
      }
      widgets.push(widget)
    }
  })

  // Achievements
  widgets.push((coursesCompleted.length || userBadges.length) && achievementsWidget)

  // Feedback Widget
  widgets.push(coursesWithFeedback.length && feedbackWidget)

  // Courses In Progress
  widgets.push(coursesInProgress.length && inProgressWidget)

  // Saved Courses
  widgets.push(coursesSaved.length && savedForLaterWidget)

  // Current Learning Path Progress
  widgets.push(inProgressLearningPath && learningPathWidgetInProgress)

  // Most Recent Badge Awarded
  widgets.push(mostRecentUserBadge && badgeWidget)

  // Go through announcements
  activeAnnouncements.forEach((announcement) => {
    let image, video

    let type = announcements.types.find(type => type.id === announcement.relationships.field_announcement_type.data.id)
    if (announcement.relationships.field_media.data) {
      image = announcements.images.find(image => image.id === announcement.relationships.field_media.data.id)
    }
    if (announcement.relationships.field_video.data) {
      video = announcements.videos.find(image => image.id === announcement.relationships.field_video.data.id)
    }

    // Hide Survey announcements for now
    let widget = type.attributes.name !== 'Survey' && {
      type: 'announcement',
      announcement: announcement,
      image: image && image,
      video: video && video,
    }

    // Only add Featured Courses if the teacher hasn't already started it, and if it's not an excluded category
    if (type.attributes.name === "Featured Course") {
      let assessmentForCourse = sortedAssessments.find(
        assessment => assessment.relationships.field_course.data.id === announcement.relationships.field_course.data.id
      )
      let course = courses.courseData && courses.courseData.find(course => course.id === announcement.relationships.field_course.data.id)
      let isExcluded = session.excludedCategories && isCourseCategoryExcluded(course, categories, session.excludedCategories)

      if (!assessmentForCourse && !isExcluded) {
        widgets.push(widget)
      }
    }
    else if (type.attributes.name !== "Important") {
      widgets.push(widget)
    }
  })

  // Push TOTD announcements after all other announcements
  let tips = sortedAnnouncements.filter((announcement) => {
    let type = announcements.types.find(type => type.id === announcement.relationships.field_announcement_type.data.id)
    return type.attributes.name === "Tip of the day"
  })

  tips.forEach((announcement) => {
    widgets.push({
      type: 'announcement',
      announcement: announcement
    })
  })

  // Social Widget
  widgets.push(socialWidget)

  // Support Widget
  widgets.push({ type: 'support' })

  return {
    session,
    widgets,
    assessments,
    categories,
    courses: courses.courseData,
    announcements,
    productTour,
    sidebar
  }
}

const mapDispatchToProps = {
  startProductTour
}

class DashboardWidget extends Component {

  render() {

    const { type } = this.props.widget

    return (
      <div className="widgetWrapper">
        {
          type === 'course-list' &&
          <WidgetCourseList
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'feedback' &&
          <WidgetFeedback
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'learningPath' &&
          <WidgetLearningPath
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'badge' &&
          <WidgetBadge
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'achievements' &&
          <WidgetAchievements
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'announcement' &&
          <WidgetAnnouncement
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'social' &&
          <WidgetSocial
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
        {
          type === 'support' &&
          <WidgetSupport
            animationDelay={this.props.animationDelay}
            widget={this.props.widget}
          />
        }
      </div>
    )
  }
}

class DashboardLayout extends Component {

  componentDidMount = () => {
    const { session, productTour } = this.props
    // Start the product tour if they haven't already
    if (!session.user.attributes.field_product_tour_started && !productTour.hasStarted) {
      this.props.startProductTour(session)
    }
  }

  render() {
    const { widgets } = this.props

    return (
      <div className="page dashboard">
        <Masonry elementType={'ul'} options={{ transitionDuration: .3 }}>
          {
            widgets.map((widget, index) => {
              if (widget) {
                return (
                  <DashboardWidget
                    key={index}
                    widget={widget}
                    animationDelay={index * .05 + 's'}
                  />
                )
              }
              else {
                return false
              }
            })
          }
        </Masonry>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);