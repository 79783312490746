import React, { Component } from 'react'
import { connect } from 'react-redux'

import BadgeItem from './BadgeItem'
import { getUserBadges } from '../actions'
import { getCourses } from 'common/actions/course'
import { isCourseCategoryExcluded } from '../functions'

import { isMobileOnly } from 'react-device-detect'
import { Grid } from '@material-ui/core'
import _ from 'lodash'
import classNames from 'classnames'

import Loading from './Loading'
import BadgeFilters from './BadgeFilters'
import BadgeFiltersEarned from './BadgeFiltersEarned'

import '../styles/cards.scss'

const mapStateToProps = ({ assessments, categories, courses, badges, navbar, session, sidebar }, ownProps) => {
  let filters, sortedUserBadges = []

  /**
   * Filter out archived badges, badges that contain excluded categories
   * and badges that are manually awarded (have zero courses)
   */
  let availableBadges = badges.badgeData && badges.badgeData.filter((badge) => {
    let isArchived = badge.attributes.field_archive
    let badgeCourses = badge.relationships.field_course.data.map((badgeCourse) => {
      return courses.courseData && courses.courseData.find(course => course.id === badgeCourse.id)
    })

    let containsExcludedCategories = badgeCourses.find(badgeCourse => session.excludedCategories && isCourseCategoryExcluded(badgeCourse, categories, session.excludedCategories))

    return !isArchived && !containsExcludedCategories && badgeCourses.length
  })

  if (navbar.activeItem === 'learn') {
    filters = sidebar.pages.learn.items.find(item => item.id === 'badges').filters
  }
  else if (navbar.activeItem === 'achievements') {
    filters = sidebar.pages.achievements.items.find(item => item.id === 'badgesEarned').filters
  }

  if (badges && session.userBadges) {
    if (filters.sort === 'name') {
      sortedUserBadges = _.sortBy(session.userBadges, (userBadge) => {
        return badges.badgeData.find(badge => badge.id === userBadge.relationships.field_badge.data.id).attributes.name
      })
    }
    else {
      sortedUserBadges = _.orderBy(session.userBadges, (userBadge) => {
        return userBadge.attributes.created
      }, filters.sort === 'dateNewest' && ['desc'])
    }
  }

  return {
    assessments,
    courses,
    sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    navbar,
    badges,
    availableBadges,
    session,
    sortedUserBadges,
    userBadges: session.userBadges,
    filters
  }
}

const mapDispatchToProps = {
  getCourses,
  getUserBadges
}

class BadgesLayout extends Component {

  state = {
    activeBadgeId: null,
    fetched: false
  }

  setActiveBadge = (id) => {
    this.setState({ activeBadgeId: id })
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    if (!this.props.session.fetched.userBadges) {
      await this.props.getUserBadges(this.props.session.user)
    }
    this.setState({ fetched: true })
  }

  filterByQuery = (badge) => {
    let { filters } = this.props
    if (badge.attributes.name.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) > -1) {
      return badge
    }
  }

  filterByRemaining = (badge) => {
    if (!this.props.filters.remainingOnly) {
      return badge
    }
    else if (!this.getUserBadgeFromBadge(badge)) {
      return badge
    }
  }

  filterByCategory = (badge) => {
    let { filters } = this.props
    let badgeCategories = badge.courses.map(course => course.relationships.field_category.data.id)

    return badgeCategories.includes(filters.category) && badge
  }

  filterBadges = (badge) => {
    let { filters } = this.props

    let byQuery = filters.searchQuery.length > 0 ? this.filterByQuery(badge) : badge
    if (!byQuery) {
      return false
    }
    else {
      let byCategory = filters.category !== 'any' ? this.filterByCategory(byQuery) : byQuery
      if (!byCategory) {
        return false
      }
      else {
        let byRemaining = this.filterByRemaining(byCategory)
        if (!byRemaining) {
          return false
        }
        else {
          return byRemaining
        }
      }
    }
  }

  getBadgeFromUserBadge = (userBadge) => {
    let targetBadgeId = userBadge.relationships.field_badge.data.id
    return this.props.badges.badgeData.find(badge => badge.id === targetBadgeId)
  }

  getUserBadgeFromBadge = (badge) => {
    return this.props.userBadges.find(userBadge => userBadge.relationships.field_badge.data.id === badge.id)
  }

  render() {

    const { badges, availableBadges, userBadges, sortedUserBadges, navbar } = this.props
    const { activeBadgeId, fetched } = this.state

    return (
      <div className={classNames('badge-library', this.props.isExpanded && 'isExpanded')}>
        {
          isMobileOnly &&
          <div className="mobileFilters">
            {
              navbar.activeItem === 'learn' &&
              <BadgeFilters />
            }
            {
              navbar.activeItem === 'achievements' &&
              <BadgeFiltersEarned />
            }
          </div>
        }

        {
          fetched ?
            <Grid container spacing={3}>
              {
                navbar.activeItem === 'learn' && badges.badgeData && badges.badgeData.filter(this.filterBadges).map((badge, index) => {

                  // Render the badge if they've already earned it, or if it's not archived
                  let isActive = this.getUserBadgeFromBadge(badge) || availableBadges.find(availableBadge => availableBadge.id === badge.id)
                  if (isActive) {
                    return (
                      <BadgeItem
                        animationDelay={index * .05 + 's'}
                        earnedBadge={this.getUserBadgeFromBadge(badge)}
                        key={badge.id}
                        badge={badge}
                        activeBadgeId={activeBadgeId}
                        setActiveBadge={this.setActiveBadge}
                      />
                    )
                  }
                  else {
                    return false
                  }
                })
              }
              {
                navbar.activeItem === 'achievements' && userBadges && sortedUserBadges.map((userBadge, index) => {
                  let badge = this.getBadgeFromUserBadge(userBadge)
                  return (
                    <BadgeItem
                      key={badge.id}
                      animationDelay={index * .05 + 's'}
                      earnedBadge={userBadge}
                      badge={badge}
                    />
                  )
                })
              }
            </Grid>
            :
            <Loading />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgesLayout);