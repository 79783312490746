import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeNavbar, getUserBadges } from '../actions'
import { getBadges, getAssessments, getLearningPaths, getUserLearningPaths, getCourses } from 'common/actions/course'
import BadgesLayout from './BadgesLayout'
import Loading from './Loading'

import CoursesCompletedLayout from './CoursesCompletedLayout'
import LearningPathsLayout from './LearningPathsLayout'

import Sidebar from './Sidebar'

const mapStateToProps = (state, ownProps) => {

  return {
    courses: state.courses,
    coursesCompleted: state.assessments.assessmentData.filter((assessment) => {
      return assessment.attributes.field_status === 'completed'
    }),
    badges: state.badges,
    categories: state.categories.categoryData,
    session: state.session,
    learningPaths: state.learningPaths,
    sidebar: state.sidebar,
    assessments: state.assessments,
  }
}

const mapDispatchToProps = {
  changeNavbar,
  getBadges,
  getUserBadges,
  getCourses,
  getLearningPaths,
  getUserLearningPaths,
  getAssessments
}

class AchievementsLayout extends Component {

  state = {
    fetched: false,
    loadingMessage: 'Getting Your Achievements...'
  }

  componentDidMount = async () => {
    const { session, courses, learningPaths, badges } = this.props

    this.props.changeNavbar('achievements')

    Promise.all([
      !courses.fetched && this.props.getCourses(this.props.session.trial),
      !learningPaths.fetched && this.props.getLearningPaths(),
      !badges.fetched && this.props.getBadges(),
      !session.fetched.userBadges && this.props.getUserBadges(this.props.session.user),
      !session.fetched.userLearningPaths && this.props.getUserLearningPaths(this.props.session.user)
    ]).then(() => {
      this.setState({ fetched: true })
    })

  }

  render() {
    const page = this.props.sidebar.pages.achievements

    return (
      <div className="layout achievements">
        <Sidebar currentPage="achievements" activeItem={page.activeItem} items={page.items} />
        {
          this.state.fetched ?
            <>
              {page.activeItem === 'badgesEarned' && <BadgesLayout />}
              {page.activeItem === 'completed' && <CoursesCompletedLayout />}
              {page.activeItem === 'learningPathsCompleted' && <LearningPathsLayout />}
            </>
            :
            <Loading message={this.state.loadingMessage} />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AchievementsLayout);
