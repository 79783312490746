
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Button } from '@material-ui/core'
import { Link } from 'react-router'
import { setSidebar } from '../actions'

const mapStateToProps = ({ session, assessments }, ownProps) => {

  let completedLearningPaths = session.userLearningPaths && session.userLearningPaths.filter(userPath => userPath.attributes.field_status === 'completed')

  return {
    session,
    completedLearningPaths, 
    completedAssessments: assessments.assessmentData.filter(assessment => assessment.attributes.field_status === 'completed'),  
    userBadges: session.userBadges
  }
}

const mapDispatchToProps = {
  setSidebar,
}

class WidgetAchievements extends Component {

  handleClick = () => {
    const { targetPage, targetTab } = this.props.widget
    this.props.setSidebar(targetPage, targetTab)
  }

  render() {
    const { userPicture, userBadges } = this.props.session
    const { label } = this.props.widget
    const { completedLearningPaths, completedAssessments } =  this.props

    let userPictureSrc = userPicture ? process.env.REACT_APP_API_URL + userPicture.attributes.uri.url : require('../img/profile-pic.svg')
    
    return (
      <Card className="widget achievements" style={{ animationDelay: this.props.animationDelay }}>
        <div className="widgetLabel success">{label}</div>

        <div className="flexRow">
          <div className="profilePicture" style={{ backgroundImage: 'url(' + userPictureSrc + ')'}}></div>
          <div className="stats">
            <h2>Your Achievements</h2>
            {
              completedAssessments.length > 0 &&
              <div className="flexRow">
                Courses Completed<span>{completedAssessments.length}</span>
              </div>
            }
            {
              userBadges && userBadges.length > 0 &&
              <div className="flexRow">
                Badges Earned<span>{userBadges.length}</span>
              </div>
            }
            {
              completedLearningPaths && completedLearningPaths.length > 0 &&
              <div className="flexRow">
                Learning Paths Completed<span>{completedLearningPaths.length}</span> 
              </div>
            }
          </div>
        </div>
        <footer>
          <Link to='/achievements'>
            <Button className="button small">View Achievements</Button>
          </Link>
        </footer>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetAchievements);