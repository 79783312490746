
import React, { Component } from 'react'
import { Link } from 'react-router'
import { Card, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import classNames from 'classnames'

const mapStateToProps = ({ courses, categories }, ownProps) => {

  return {
    courses: courses.courseData,
    categories,
  }
}

class WidgetFeedback extends Component {

  render() {
    const { assessment, label } = this.props.widget
    const { categories, courses } = this.props

    let assessmentCourse = courses.find(course => course.id === assessment.relationships.field_course.data.id)
    let coursePath = '/course/' + assessmentCourse.attributes.drupal_internal__id
    let categoryId = assessmentCourse.relationships.field_category.data.id
    let category = categories.find(cat => cat.id === categoryId)
    let categoryLabel = category.attributes.name.toLowerCase()  

    return (
      <Card className='widget feedback' style={{ animationDelay: this.props.animationDelay }}>
        <Link to={coursePath}>
          <div className='widgetLabel success'>{label}</div>        
          <div className='widgetIcon icon feedback' />

          <div className="flexRow">
            <div className='category-icon-wrapper'>
              <span className={classNames('category-icon', categoryLabel)}/>
            </div>
            <p>Check out this feedback on your challenge for <strong>{assessmentCourse.attributes.name}</strong>.</p>
          </div>

          <p className="content">
            <em>"{assessment.attributes.field_comments}"</em>
          </p>

          <footer>
            <Button className="button small">View Course</Button>
          </footer>
        </Link>
      </Card>
    )
  }
}

export default connect(mapStateToProps)(WidgetFeedback)