
import React, { Component } from 'react'
import { Card, Button } from '@material-ui/core'

class WidgetSocial extends Component {

  render() {
    
    return (
      <Card className="widget social" style={{ animationDelay: this.props.animationDelay }}>
        <div className="widgetLabel">{this.props.widget.label}</div>

        <p>Connect with us and get the latest news from MobileMind!</p>

        <div className="flexRow">
          <div className="logo circle" />
          <div className="links">
            {
              this.props.widget.links.map((link, index) => {
                return (
                  <a key={index} target={"_blank"} href={link.href}>
                    <Button className="button small">
                      <span className={link.iconClasses} />
                      {link.name}
                    </Button>
                  </a>
                )
              })
            }
            </div>
        </div>
      </Card>
    )
  }
}

export default WidgetSocial;