import moment from 'moment'
import qs from 'qs'

let options = {
  credentials: 'include',
}

export function deleteNotification(notification) {
  return async (dispatch, getState) => {

    let options = {
      credentials: 'include',
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json',
        'X-CSRF-Token': getState().session.token
      }),
    }
    try {
      await fetch(process.env.REACT_APP_API_URL + '/api/notification_entity/notification_entity/' + notification.id, options)
    }
    catch (err) {
    }

  }
}
export function getNotifications(user) {

  return async (dispatch) => {
    let query = {
      filter: {
        'field_user.id': user.id
      }
    }

    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/notification_entity/notification_entity?' + qs.stringify(query), options)
      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: 'GET_NOTIFICATIONS',
          data: data.data
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function markNotificationAsSeen(notification) {

  return async (dispatch, getState) => {

    let newNotification = {
      "data": {
        "id": notification.id,
        "type": "notification_entity--notification_entity",
        "attributes": {
          "field_notification_seen": true
        },
      }
    }

    let options = {
      credentials: 'include',
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json',
        'X-CSRF-Token': getState().session.token
      }),
      body: JSON.stringify(newNotification)
    }

    try {
      await fetch(process.env.REACT_APP_API_URL + '/api/notification_entity/notification_entity/' + notification.id, options)
    } catch (err) {
      console.log(err)
    }
  }

}

export function getNPSSurvey(user) {

  return async (dispatch) => {

    let query = {
      filter: {
        'user_id.id': user.id,
      }
    }

    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/survey_entity/net_promoter_score?' + qs.stringify(query), options)
      if (response.ok) {
        let data = await response.json()
        let lastSurvey = data.data.length && data.data[data.data.length - 1]

        dispatch({
          type: 'GET_NET_PROMOTER_SCORE',
          data: lastSurvey,
          fetched: true
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function submitNPSSurvey(survey) {
  return async (dispatch, getState) => {
    try {

      let options = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json',
          'X-CSRF-Token': getState().session.token
        }),
      }

      options.body = JSON.stringify({
        "data": {
          "type": "survey_entity--net_promoter_score",
          "attributes": survey,
        }
      })

      await fetch(process.env.REACT_APP_API_URL + '/api/survey_entity/net_promoter_score', options)
    } catch (err) {
      console.log(err)
    }
  }
}

export function getExtensionVersion(version) {
  return async (dispatch) => {
    dispatch({
      type: 'GET_EXTENSION_VERSION',
      version: version,
    })
  }
}

export function getCategories(dispatch) {

  return async (dispatch) => {
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/taxonomy_term/category', options)
      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: 'GET_CATEGORIES',
          data: data,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getJobTitles(dispatch) {

  return async (dispatch) => {
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/taxonomy_term/job_titles', options)
      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: 'GET_JOB_TITLES',
          data: data,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getBadge(id) {
  return async (dispatch) => {
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/badges_entity/badges_entity/' + id + '?include=field_badge_image', options)
      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: 'GET_BADGE',
          data: data
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function collapseSidebar(isCollapsed) {
  let type = isCollapsed ? 'SHOW' : 'COLLAPSE'
  return (dispatch) => {
    dispatch({ type: type })
  }
}

export function setSidebar(page, activeItem) {
  return (dispatch) => {
    dispatch({
      type: 'SELECT_ITEM',
      page: page,
      activeItem: activeItem
    })
  }
}

export function endProductTour() {
  return (dispatch) => {
    dispatch({ type: 'END_PRODUCT_TOUR' })
  }
}

export function startProductTour(session, trigger) {
  return async (dispatch) => {

    dispatch({ type: 'START_PRODUCT_TOUR', trigger: trigger })

    let newUser = {
      "data": {
        "id": session.user.id,
        "type": "user--user",
        "attributes": {
          "field_product_tour_started": new moment().format()
        },
      }
    }

    let options = {
      credentials: 'include',
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json',
        'X-CSRF-Token': session.token
      }),
      body: JSON.stringify(newUser)
    }

    try {
      await fetch(process.env.REACT_APP_API_URL + '/api/user/user/' + session.user.id, options)
    } catch (err) {
      console.log(err)
    }
  }
}

export function setViewStyle(style) {
  return (dispatch) => {
    dispatch({
      type: 'SET_VIEW_STYLE',
      data: style
    })
  }
}

export function updateFilters(filters, tab) {
  return async (dispatch) => {

    switch (tab) {
      case 'mobile':
        dispatch({
          type: 'EXPAND_MOBILE_FILTERS',
          data: filters
        })
        break
      case 'learningPaths':
        dispatch({
          type: 'UPDATE_LEARNING_PATH_FILTERS',
          data: filters
        })
        break
      case 'completed':
        dispatch({
          type: 'UPDATE_COMPLETED_FILTERS',
          data: filters
        })
        break
      case 'badgesEarned':
        dispatch({
          type: 'UPDATE_BADGES_EARNED_FILTERS',
          data: filters
        })
        break
      case 'earnBadges':
        dispatch({
          type: 'UPDATE_BADGE_FILTERS',
          data: filters
        })
        break
      default:
        dispatch({
          type: 'UPDATE_EXPLORE_FILTERS',
          data: filters
        })
    }

  }
}

export function getUserBadges(user) {

  return async (dispatch, getState) => {
    try {
      let query = {
        filter: {
          'field_teacher.id': user.id,
        }
      }

      let response = await fetch(process.env.REACT_APP_API_URL + '/api/user_badge/user_badge?' + qs.stringify(query), options)

      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: 'GET_USER_BADGES',
          data: data.data,
          fetched: true
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

}

export function changeNavbar(activeItem) {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_NAVBAR',
      activeItem: activeItem
    })
  }
}
export function getAnnouncements() {

  return async (dispatch, getState) => {
    try {

      let response = await fetch(process.env.REACT_APP_API_URL + '/api/node/announcement?include=field_announcement_type,field_media,field_video,field_survey', options)
      let group = getState().session.group
      let groupId = group && getState().session.group.uuid[0].value

      if (response.ok && groupId) {
        let data = await response.json()
        let filteredByGroup = data.data.filter((announcement) => {

          // Return the announcement if it has no group
          if (!announcement.relationships.field_group.data.length) {
            return true
          }
          else {
            // Or if there are multiple groups and one of them is the user's
            return announcement.relationships.field_group.data.map(group => group.id).includes(groupId)
          }
        })

        // Filter by the users the announcement is for
        let filteredByUser = filteredByGroup.filter((announcement) => {
          let users = announcement.relationships.field_user.data.map(user => user.id)
          return users.length < 1 || users.includes(getState().session.user.id)
        })

        // Only show if they have the required number of courses completed
        let filteredByCoursesComplete = filteredByUser.filter((announcement) => {
          let coursesComplete = announcement.attributes.field_courses_complete

          if (coursesComplete) {
            let completed = getState().assessments.assessmentData.filter((assessment) => {
              return assessment.attributes.field_status === 'completed'
            })
            return completed.length >= coursesComplete
          }
          else {
            return true
          }
        })

        dispatch({
          type: 'GET_ANNOUNCEMENTS',
          data: filteredByCoursesComplete,
          included: data.included
        })

      }
    } catch (err) {
      console.log(err)
    }
  }

}

export function createAssessment(session, activeCourse, status) {

  let assessment = {
    "data": {
      "type": "assessment_entity--assessment_entity",
      "attributes": {
        "status": true,
        "field_status": status ? status : "in_progress",
        "field_last_updated_by": [{
          value: session.user.attributes.drupal_internal__uid
        }],
      },
      "relationships": {
        "field_teacher": {
          "data": {
            "type": "user--user",
            "id": session.user.id
          }
        },
        "field_course": {
          "data": {
            "type": "course_entity--course_entity",
            "id": activeCourse.id
          }
        }
      }
    }
  }

  let options = {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'X-CSRF-Token': session.token
    }),
    body: JSON.stringify(assessment)
  }

  return async (dispatch) => {
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/assessment_entity/assessment_entity', options)
      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }

}
export function startOnboarding() {
  return async (dispatch) => {
    dispatch({ type: 'START_ONBOARDING' })
  }
}

export function endOnboarding() {
  return async (dispatch) => {
    dispatch({ type: 'END_ONBOARDING' })
  }
}


/**
 * Create a user_learning_path entity for a learning path, and roll up any completed courses in the process
 * @param {object} session The current user's session
 * @param {object} learningPath The Learning Path entity to reference
 * @param {array} completedCourseIds An array of course_entity ids for courses from this path that the teacher has already completed
 */

export function createUserLearningPath(session, learningPath, completedCourseIds) {

  let courseIds = completedCourseIds.map((courseId) => {
    return { "type": "course_entity--course_entity", "id": courseId }
  })

  let isCompleted = completedCourseIds.length === learningPath.relationships.field_courses.data.length

  let path = {
    "data": {
      "type": "assessment_entity--assessment_entity",
      "attributes": {
        "name": 'PathId: ' + learningPath.attributes.drupal_internal__id + ' for ' + session.user.attributes.name,
        "status": true,
        "field_status": isCompleted ? "completed" : "in_progress",
      },
      "relationships": {
        "field_courses_completed": {
          "data": courseIds
        },
        "field_learning_path": {
          "data": {
            "type": "learning_path--learning_path",
            "id": learningPath.id
          }
        },
        "field_user": {
          "data": {
            "type": "user--user",
            "id": session.user.id
          }
        },
      }
    }
  }

  let options = {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json',
      'X-CSRF-Token': session.token
    }),
    body: JSON.stringify(path)
  }

  return async (dispatch) => {
    try {
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/user_learning_path/user_learning_path', options)
      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }

}

